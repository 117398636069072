import React, { useState } from "react";
import styles from "./style.module.css";
import { IMAGES } from "assets/images";
import { toast } from "react-toastify";

interface FileUploadProps {
  onUpload: any;
  video: any;
}

export const Uploading: React.FC<FileUploadProps> = ({ onUpload, video }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleSelectedFile(e.target.files[0]);
      video(e.target.files[0]);
      onUpload((prevState: any) => ({
        ...prevState,
        file: selectedFile,
      }));
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleSelectedFile(e.dataTransfer.files[0]);
      video(e.dataTransfer.files[0]);
      onUpload((prevState: any) => ({
        ...prevState,
        file: selectedFile,
      }));
    }
  };

  const handleSelectedFile = (file: File) => {
    if (file.type.startsWith("video/") && file.size <= 70 * 1024 * 1024) {
      setSelectedFile(file);
      video(file);
      // setShowModal(true);

      // const xhr = new XMLHttpRequest();
      // xhr.open("POST", "/upload", true);

      // xhr.upload.onprogress = (e) => {
      //   setUploadProgress((e.loaded / e.total) * 100);
      // };

      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === XMLHttpRequest.DONE) {
      //     if (xhr.status === 200) {
      //       // Handle successful upload
      //       onUpload(file);
      //       setShowModal(false); // close the modal
      //     } else {
      //       // Handle upload error
      //       toast.error("Upload failed!");
      //     }
      //   }
      // };

      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   xhr.send(reader.result);
      // };
    } else {
      toast.error("Invalid file format or size");
      console.log("Invalid file format or size");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <p style={{ marginBottom: "12px" }}>
        Upload video
        <span
          style={{ color: "red", display: "inline-block", marginLeft: "5px" }}
        >
          *
        </span>
      </p>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className={styles.uploadInput}
      >
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <img
          src={IMAGES.VIDEO_PLAY_ACTIVE}
          width={40}
          alt="video active"
          style={{ color: "blue" }}
        />
        <p>
          {selectedFile ? (
            <p>Selected file: {selectedFile.name}</p>
          ) : (
            <>
              Drag and drop your video here or
              <button
                className={styles.browseText}
                onClick={() =>
                  (
                    document.querySelector(
                      'input[type="file"]'
                    ) as HTMLInputElement
                  )?.click()
                }
              >
                Browse
              </button>
            </>
          )}
        </p>
        {!selectedFile && (
          <small style={{ color: "#ccc" }}>Max 70MB for video </small>
        )}
      </div>
      {/* {showModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            background: "white",
            padding: "16px",
          }}
        >
          <div className={styles.uploadingContainer}>
            <div className={styles.detailsContainer}>
              <img src={IMAGES.UPLOADING_VIDEO} width={40} alt="video image" />
              <div className={styles.details}>
                <h4>{selectedFile?.name}...</h4>
                <small
                  style={{
                    color: "#ccc",
                    display: "inline-block",
                    marginBottom: "12px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sit purus.
                </small>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>{uploadProgress}% completed</p>
              <progress
                value={uploadProgress}
                max="100"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
