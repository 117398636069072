import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8BT9XECAD_UprwLuWkexJL8C085vypbQ",
  authDomain: "assess-progress.firebaseapp.com",
  databaseURL:
    "https://assess-progress-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "assess-progress",
  storageBucket: "assess-progress.appspot.com",
  messagingSenderId: "55310914080",
  appId: "1:55310914080:web:a11c2ba0e825b25538a245",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
