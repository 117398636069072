import { IMAGES } from "assets/images";
import Actions from "components/actions";

export const MOCK_DATA = {
  aboutData: [
    {
      id: 1,
      image: require("assets/images/document.png"),
      title: "Assessment",
      description:
        "Discover some real insights on yourself. Such as your skills, strengths, where you should invest time and effort improving to maximize your potential.",
    },
    {
      id: 2,
      image: require("assets/images/receipt-search.png"),
      title: "Resume review",
      description:
        "If your resume struggles to get on the yes pile, it could be the HR technology. Our resume architect shows you how the bots see it and flags issues to fix.",
    },
    {
      id: 3,
      image: require("assets/images/battery-full.png"),
      title: "Skill development",
      description:
        "Standing still? It may be time to enhance your job skills to land a promotion or make your next career move.",
    },
    {
      id: 4,
      image: require("assets/images/interview.png"),
      title: "Interview prep",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
    {
      id: 5,
      image: require("assets/images/user-octagon.png"),
      title: "Personalize coaching",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
    {
      id: 6,
      image: require("assets/images/book.png"),
      title: "eLearning",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
  ],

  contactData: [
    {
      id: 1,
      title: "Assess@th.assesstm.com",
      image: require("assets/images/sms.png"),
      link: "mailto:Assess@th.assesstm.com",
    },
    {
      id: 2,
      title: "01256987463",
      image: require("assets/images/call.png"),
      link: "tel:+01256987463",
    },
    {
      id: 3,
      title: "6 october city",
      image: require("assets/images/location.png"),
      link: "https://www.google.com/maps/place/Badgewell+Software/@30.0003917,30.9738038,17z/data=!3m1!4b1!4m5!3m4!1s0x145857ebf99a093b:0xa4eeb8e51676f132!8m2!3d30.0003917!4d30.9759925",
    },
  ],

  socialLinks: [
    {
      id: 1,
      path: require("assets/images/Facebook.png"),
      link: "https://www.facebook.com/assesstm",
    },
    // {
    //   id: 2,
    //   path: require("assets/images/twitter.png"),
    //   link: "",
    // },
    {
      id: 3,
      path: require("assets/images/linked.png"),
      link: "https://www.linkedin.com/company/assess-talent-management/mycompany/",
    },
  ],

  videos: [
    {
      id: 1,
      image: require("assets/images/v.png"),
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Approved",
    },
    {
      id: 2,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 3,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Request modification",
      image: require("assets/images/v.png"),
    },
    {
      id: 4,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "In review",
      image: require("assets/images/v.png"),
    },
    {
      id: 5,
      image: require("assets/images/v.png"),
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Approved",
    },
    {
      id: 6,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 7,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 8,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 9,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 10,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 11,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 11,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
    {
      id: 12,
      name: "How to improve your resume",
      uploadDate: "22/2/2023",
      topic: "Improve resume",
      mentorName: "Amr Essam",
      status: "Rejected",
      image: require("assets/images/v.png"),
    },
  ],

  overviewDetails: [
    {
      id: 1,
      imgPath: IMAGES.COIN,
      count: 5000,
      percentage: "+10%",
      title: "Total Revenue",
    },
    {
      id: 2,
      imgPath: IMAGES.EYE,
      count: 10000,
      percentage: "-10%",
      title: "Total Viewers",
    },
    {
      id: 3,
      imgPath: IMAGES.ARROW2,
      count: 20000,
      percentage: "+10%",
      title: "Total Visit",
    },
    {
      id: 4,
      imgPath: IMAGES.IMPORT,
      count: 100000,
      percentage: "+10%",
      title: "Total Download",
    },
  ],

  tabs: [
    {
      id: 0,
      destination: "/dashboard/overview",
      imgPathActive: IMAGES.OVERVIEW_ACTIVE,
      title: "Overview",
      imgPathNotActive: IMAGES.OVERVIEW,
    },
    {
      id: 1,
      destination: "/dashboard/users",
      imgPathActive: IMAGES.USER_ACTIVE,
      title: "Users List",
      imgPathNotActive: IMAGES.USER,
    },
    {
      id: 2,
      destination: "/dashboard/mentors",
      imgPathActive: IMAGES.TEACHER_ACTIVE,
      title: "Mentors List",
      imgPathNotActive: IMAGES.TEACHER,
    },
    {
      id: 3,
      destination: "/dashboard/categories",
      imgPathActive: IMAGES.BRIEF_CASE_ACTIVE,
      title: "Careers",
      imgPathNotActive: IMAGES.BRIEFCASE,
      subNav: [
        {
          id: 0,
          label: "Categories",
          path: "/dashboard/categories",
        },
        {
          id: 1,
          label: "Careers",
          path: "/dashboard/careers",
        },
      ],
    },
    {
      id: 6,
      destination: "/dashboard/videos",
      imgPathActive: IMAGES.VIDEO_PLAY_ACTIVE,
      title: "Videos",
      imgPathNotActive: IMAGES.VIDEO_PLAY,
      subNav: [
        {
          id: 0,
          label: "Videos",
          path: "/dashboard/videos",
        },
        {
          id: 1,
          label: "Topics",
          path: "/dashboard/topics",
        },
      ],
    },
    {
      id: 9,
      destination: "/dashboard/sessions",
      imgPathActive: IMAGES.VIDEO_ACTIVE,
      title: "Sessions",
      imgPathNotActive: IMAGES.VIDEO,
    },
    {
      id: 10,
      destination: "/dashboard/assessments",
      imgPathActive: IMAGES.NOTE_ACTIVE,
      title: "Assessments",
      imgPathNotActive: IMAGES.NOTE,
    },
  ],

  profile: [
    {
      id: 1,
      name: "Account settings",
      icon: IMAGES.PROFILE,
      destination: "/dashboard/settings",
    },
    {
      id: 2,
      name: "Settings",
      icon: IMAGES.SETTINGS,
      destination: "/dashboard/accountSettings/applicationSettings",
    },
    { id: 3, name: "Logout", icon: IMAGES.LOGOUT },
  ],

  settings: [
    {
      id: 1,
      name: "My information",
    },
    {
      id: 2,
      name: "Password",
    },
    {
      id: 3,
      name: "Notifications",
    },
  ],

  userPhotos: [
    {
      id: 1,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 2,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 3,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 4,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 5,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 6,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 7,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 8,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 9,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
    {
      id: 10,
      src: IMAGES.PROFILE_HOLDER,
      alt: "user photo",
    },
  ],
  tagItems: [
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
    "AdobeXD",
    "Figma",
  ],

  myPasswords: [
    {
      id: 1,
      name: "Current password",
    },
    {
      id: 2,
      name: "New password",
    },

    {
      id: 3,
      name: "Confirm new password",
    },
  ],

  userNotifications: [
    { id: 4, name: "None" },
    { id: 5, name: "In-app" },
    { id: 6, name: "Email" },
  ],
  languages: [
    { id: 0, title: "Arabic" },
    { id: 1, title: "English" },
    { id: 2, title: "French" },
  ],
  breadcrumbTabs: [
    { id: 1, label: "Add career", destination: "/dashboard/career" },
    {
      id: 2,
      label: "Add details",
      destination: "AddDetails",
    },
    {
      id: 3,
      label: "Add tasks",
      destination: "AddTasks",
    },
  ],
  tasksList: [
    {
      id: 0,
      label: "Task 1",
    },
    {
      id: 1,
      label: "Task 1",
    },
    {
      id: 2,
      label: "Task 1",
    },
    {
      id: 3,
      label: "Task 1",
    },
    {
      id: 4,
      label: "Task 1",
    },
  ],

  languageTabs: [
    {
      id: 1,
      name: "English",
      image: IMAGES.UK_FLAG,
      topicPlaceHolder: "Topic name",
      descriptionPlaceHolder: "Write here...",
    },
    {
      id: 2,
      name: "Arabic",
      image: IMAGES.EG_FLAG,
      topicPlaceHolder: "اسم الفئه",
      descriptionPlaceHolder: "....اكتب هنا",
    },
    {
      id: 3,
      name: "French",
      image: IMAGES.FR_FLAG,
      topicPlaceHolder: "Nom du sujet",
      descriptionPlaceHolder: "Écrivez ici...",
    },
  ],

  CategoryFormInputs: [
    {
      id: 1,
      required: true,
      label: "Category Name",
      placeholder: "category name",
      name: "name",
    },
    {
      id: 2,
      required: true,
      label: "Slug ",
      placeholder: "Write here ...",
      name: "slug",
    },
    {
      id: 3,
      required: false,
      label: "Description ",
      placeholder: "Write here ...",
      name: "description",
    },
  ],

  CategorySecondFormInputs: [
    {
      id: 1,
      required: true,
      label: "Category Name",
      placeholder: "category name",
      name: "name",
    },

    {
      id: 2,
      required: false,
      label: "Description ",
      placeholder: "Write here ...",
      name: "description",
    },
  ],

  TopicFormInputs: [
    {
      id: 1,
      required: true,
      label: "Topic Name",
      placeholder: "topic name",
      name: "name",
    },

    {
      id: 2,
      required: false,
      label: "Description ",
      placeholder: "Write here ...",
      name: "description",
    },
  ],

  TopicSecondFormInputs: [
    {
      id: 1,
      required: true,
      label: "Topic Name",
      placeholder: "topic name",
      name: "name",
    },

    {
      id: 2,
      required: false,
      label: "Description ",
      placeholder: "Write here ...",
      name: "description",
    },
  ],

  MentorFormInputs: [
    {
      id: 1,
      required: true,
      label: "Mentor display Name",
      placeholder: "Mentor name",
      name: "name",
    },
    {
      id: 2,
      required: true,
      label: "Job title ",
      placeholder: "Job title",
      name: "job",
    },
    {
      id: 3,
      required: true,
      label: "Email ",
      placeholder: "Email",
      name: "email",
    },
    {
      id: 4,
      required: true,
      label: "Mentor overview ",
      placeholder: "Mentor overview",
      name: "overview",
    },
  ],

  MentorSecondFormInputs: [
    {
      id: 1,
      required: true,
      label: "Mentor display Name",
      placeholder: "Mentor name",
      name: "name",
    },
    {
      id: 2,
      required: true,
      label: "Job title ",
      placeholder: "Job title",
      name: "job",
    },

    {
      id: 3,
      required: true,
      label: "Mentor overview ",
      placeholder: "Mentor overview",
      name: "overview",
    },
  ],

  notifications: [
    {
      id: 0,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 2,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 3,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 4,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 5,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 6,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 7,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 8,
      title: "video added",
      subtitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    },
  ],
  careerFormInputs: [
    {
      id: 2,
      required: true,
      label: "Career name",
      placeholder: "career name",
      name: "name",
      onChange: (e: any) => {
        const value: string = e.target.value;
        if (!value.length) {
          return "Select one of the categories, please";
        } else return "category selected";
      },
    },
    {
      id: 3,
      required: true,
      label: "Career overview",
      placeholder: "career overview",
      onChange: (e: any) => {
        const value: string = e.target.value;
        console.log("fired", value);
        if (!value.length) {
          return "Select one of the categories, please";
        } else return "category selected";
      },
      name: "overView",
    },

    {
      id: 4,
      required: false,
      label: "Work Culture",
      placeholder: "work culture",
      name: "workCulture",
      onChange: (e: any) => {
        const value: string = e.target.value;
        console.log("fired", value);
        if (!value.length) {
          return "Select one of the categories, please";
        } else return "category selected";
      },
    },
  ],
  accountSettingsData: [
    {
      id: 0,
      label: "Time Zone",
      options: ["Option 1", "Option 2", "Option 3"],
    },
    {
      id: 1,
      label: "Example",
      options: ["Option 1", "Option 2", "Option 3"],
    },
    {
      id: 2,
      label: "Example",
      options: ["Option 1", "Option 2", "Option 3"],
    },
    {
      id: 3,
      label: "Example",
      options: ["Option 1", "Option 2", "Option 3"],
    },
  ],
  mentorsData: [
    {
      id: 1,
      mentorName: "Jon Snow",
      email: "jonsnow@gmail.com",
      userType: "admin",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 2,
      mentorName: "Cersei Lannister",
      email: "cerseilannister@gmail.com",
      userType: "manager",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 3,
      mentorName: "Jaime Lannister",
      email: "jaimelannister@gmail.com",
      userType: "user",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 4,
      mentorName: "Anya Stark",
      email: "anyastark@gmail.com",
      age: 16,
      phone: "(921)425-6742",
      actions: "",
      userType: "admin",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 5,
      mentorName: "Daenerys Targaryen",
      email: "daenerystargaryen@gmail.com",
      age: 31,
      phone: "(421)445-1189",
      userType: "user",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 6,
      mentorName: "Ever Melisandre",
      email: "evermelisandre@gmail.com",
      age: 150,
      phone: "(232)545-6483",
      userType: "manager",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 7,
      mentorName: "Ferrara Clifford",
      email: "ferraraclifford@gmail.com",
      career: "UX Designer",
      phone: "(543)124-0123",
      userType: "user",
      createdBy: "Admin",
    },
    {
      id: 8,
      mentorName: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      userType: "user",
      career: "UX Designer",
      createdBy: "Admin",
    },
    {
      id: 9,
      mentorName: "Harvey Roxie",
      email: "harveyroxie@gmail.com",
      age: 65,
      phone: "(444)555-6239",
      userType: "admin",
      career: "UX Designer",
      createdBy: "Admin",
    },
  ],
};
