import { ICategory } from "models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
  category: ICategory | undefined;
}

const initialState: IInitialState = {
  category: undefined,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<ICategory | undefined>) => {
      console.log(action.payload);
      state.category = action.payload;
    },
  },
});

export const { setCategory } = categorySlice.actions;
export default categorySlice.reducer;
