import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridMenu } from "@mui/x-data-grid";
import styles from "./style.module.css";
import Actions from "components/actions";
import { DATAGRIDSTYLE, MOCK_DATA } from "utils";
import { IVideo } from "models/videos";
import { useNavigate } from "react-router-dom";
import { deleteVideoApi, getAllPaginatedVideos } from "services/videos";
import { toast } from "react-toastify";
import {
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
} from "components/shared/datagridOverlays/customOverlays";

export const VideosDataGrid: React.FC = () => {
  let page = 1;
  let limit = 10;
  //videos data
  const [rows, setRows] = useState<IVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  //video approval
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IVideo>();
  // pagination & deletion
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  });
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const navigation = useNavigate();

  useEffect(() => {
    _getAllVideos(1);
  }, []);

  const _getAllVideos = (page: number) => {
    setLoading(true);
    getAllPaginatedVideos({ page, limit })
      .then((res: any) => {
        setRows(res.data.docs);
        setPaginationData({
          currentPage: res?.data?.currentPage,
          itemsPerPage: res?.data?.itemsPerPage,
          totalItems: res?.data?.totalItems,
          totalPages: res?.data?.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const _delete = (id: string) => {
    setDeleteLoading(true);
    deleteVideoApi(id)
      .then((_) => {
        toast.success("Topic deleted Successfully");
      })
      .then((_) => {
        setDeleteLoading(false);
        _getAllVideos(page);
      })
      .catch((e) => {
        setDeleteLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Videos",
      flex: 1,
      headerClassName: styles.title,
      renderCell: ({ row: { image, name } }) => {
        return (
          <div style={{ display: "flex" }}>
            <img src={image} alt="video image" width={25} />
            <span style={{ width: "5px" }} />
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "uploadDate",
      headerName: "Upload date",
      headerClassName: styles.title,
      flex: 1,
    },
    {
      field: "topic",
      headerName: "Topic",
      headerClassName: styles.title,
      flex: 1,
    },
    {
      field: "mentorName",
      headerName: "Mentor Name",
      flex: 1,
      headerClassName: styles.title,
    },
    {
      field: "actions",
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      headerClassName: styles.title,
      disableColumnMenu: true,
      renderCell: ({ row, row: { id } }) => (
        <Actions
          loading={deleteLoading}
          onDelete={() => _delete(id)}
          onEdit={() => {
            navigation("/dashboard/addVideo", {
              state: {
                edit: true,
                item: row,
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      {!loading && (
        <DataGrid
          sx={DATAGRIDSTYLE}
          rows={rows}
          columns={columns}
          pagination
          autoPageSize
          checkboxSelection={true}
          components={{
            NoRowsOverlay: () =>
              CustomNoRowsOverlay(
                "No videos found",
                " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ),
            LoadingOverlay: CustomLoadingOverlay,
            // ColumnMenu:
          }}
          // paginationMode="server"
          // keepNonExistentRowsSelected
        />
      )}
    </div>
  );
};
