import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from "axios";
import { RootState } from "interface/model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleAxiosError } from "utils/helperFun";
import { API } from "utils/interceptor";

export const useAxios = <T>(configParams: AxiosRequestConfig) => {
  const [res, setRes] = useState<AxiosResponse<T>>();
  const [err, setErr] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: RootState) => state.userState);
  interface MethodParams {
    isShowErrorToast?: boolean;
    successMessage?: string;
    data?: any;
    Toast?: boolean;
    headers?: RawAxiosRequestHeaders;
  }

  const method = async (params?: MethodParams) => {
    setErr("");
    setLoading(true);

    const config = { ...configParams };
    if (config.method?.toLocaleLowerCase() != "get")
      config.data = { ...configParams.data, ...params?.data };
    if (params?.headers && Object.keys(params.headers).length > 0)
      config.headers = { ...configParams.headers, ...params.headers };

    return await API.request(config)
      .then((response: AxiosResponse<T>) => {
        console.log({ response });

        setRes(response);
        // if (params?.Toast) {
        //   toast.error(params.Toast);
        // } else {
        params?.successMessage && toast.success(params.successMessage);
        // }

        return Promise.resolve(response);
      })
      .catch(async (error: AxiosError) => {
        setErr(handleAxiosError(error));

        if (error.response?.status == 401) {
          // } else if (params?.Toast) {
          //   toast.error(params.Toast);
        } else {
          params?.isShowErrorToast && toast.error(handleAxiosError(error));
        }
        return Promise.reject(error);
      })
      .finally(() => setLoading(false));
  };
  return { res, err, loading, method };
};

interface IResponse<T> {
  data: T[];
  totalPages?: number;
}

interface IHeaders {
  [key: string]: string;
}

export const useAxiosWithPagination = <T>(
  url: string,
  page: number,
  headers: IHeaders = {}
): [T[], number | undefined, boolean, string] => {
  const [data, setData] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: AxiosResponse<IResponse<T>> = await axios.get(
          process.env.REACT_APP_BASEURL + url,
          {
            params: { page, pageSize: 10 }, // pageSize: limit.
            headers,
          }
        );
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, page]);

  return [data, totalPages, loading, error];
};
