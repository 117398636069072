import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Categories } from "pages/categories";
import AddTopic from "pages/addTopic";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import {
  AddCareer,
  AddDetails,
  AddTasks,
  CustomBreadcrumb,
  NoPage,
} from "./components";
import {
  LandingPageScreen,
  Dashboard,
  Overview,
  Users,
  MentorsList,
  Careers,
  Sessions,
  Videos,
  Assessments,
  Settings,
  Topics,
  TasksList,
  EditUser,
  AddVideo,
  AccountSettings,
} from "./pages";
import { ScrollToTop } from "./utils";
import { Provider } from "react-redux";
import { store } from "../src/state";
import AddCategory from "pages/addcategory/addCategory";
import { useEffect, useState } from "react";
import ReactNotifications from "components/notifications/toastContainer";
import Notifications from "components/notifications/notifications";
import { onMessageListener } from "firebaselint";
import AddMentor from "pages/addMentor/addMentor";
import { ApplicationSettings } from "pages/accountSettingPages";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
export const App = () => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState({
    title: "",
    body: "",
  });

  console.log(show, notifications);

  onMessageListener()
    .then((payload) => {
      console.log({ payload });
      setShow(true);
      setNotifications({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => {
      console.log({ err });
    });

  return (
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          {show && (
            <ReactNotifications
              title={notifications.title}
              body={notifications.body}
            />
          )}

          <div className="App">
            <ScrollToTop />
            <Notifications />

            <Routes>
              <Route path="/" index element={<LandingPageScreen />} />
              <Route path="*" element={<NoPage />} />

              <Route path="/dashboard" element={<Dashboard />}>
                <Route index path="overview" element={<Overview />} />
                <Route path="users" element={<Users />} />
                <Route path="mentors" element={<MentorsList />} />

                <Route path="careers" element={<Careers />} />
                <Route path="categories" element={<Categories />} />
                <Route path="sessions" element={<Sessions />} />
                <Route path="videos" element={<Videos />} />
                <Route path="topics" element={<Topics />} />
                <Route path="addTopic" element={<AddTopic />} />
                <Route path="addCategory" element={<AddCategory />} />
                <Route path="addMentor" element={<AddMentor />} />
                <Route path="addVideo" element={<AddVideo />} />

                <Route path="assessments" element={<Assessments />} />

                <Route path="settings" element={<Settings />} />
                <Route path="accountSettings" element={<AccountSettings />}>
                  <Route
                    index
                    path="applicationSettings"
                    element={<ApplicationSettings />}
                  />
                </Route>
                <Route path="career" element={<CustomBreadcrumb />}>
                  <Route index element={<AddCareer />} />
                  <Route path="addDetails" element={<AddDetails />} />
                  <Route path="addTasks" element={<AddTasks />} />
                </Route>
                <Route path="tasksList" element={<TasksList />} />
                <Route path="editUser" element={<EditUser />} />
              </Route>

              {/* <Route path="/privacy" element={<Privacy />} /> */}
            </Routes>
            <ToastContainer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
