import { MOCK_DATA } from "utils";
import styles from "./style.module.css";
import { useAxios } from "hooks";
import { useEffect, useState } from "react";
import { ITimeZone } from "models/tag";

type Props = {
  item: {
    label: string;
    options: string[];
    id: number;
  };
  timeZones: ITimeZone[];
};

export const ApplicationSettings = () => {
  const { accountSettingsData } = MOCK_DATA;
  const [timeZones, setTimeZones] = useState<ITimeZone[]>();

  const { method: getTimeZone, loading } = useAxios<ITimeZone[]>({
    url: "/timezones",
    method: "get",
  });

  useEffect(() => {
    getTimeZone({
      isShowErrorToast: true,
      Toast: true,
    }).then((res) => {
      setTimeZones(res.data);
    });
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Application settings</h2>
      <div style={{ fontSize: "14px", color: "#ccc", marginBottom: "40px" }}>
        Lorem ipsum dolor sit amet consectetur. Cras arcu molestie gravida
        habitant egestas non vel maecenas. Facilisi.
      </div>
      {accountSettingsData?.map((item) => {
        return (
          <CustomDropdown timeZones={timeZones} key={item.id} item={item} />
        );
      })}
    </div>
  );
};

export const CustomDropdown = ({ item, timeZones }: Props) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (item?.id == 0) {
      const value = event.target.value;
      const selectedOption: ITimeZone = timeZones?.find(
        (option) => option.value === value
      );
      // alert(selectedOption?.value);
    } else {
    }
  };

  return (
    <div className={styles.dropdownHolder}>
      <label className={styles.label}>{item?.label}</label>
      <select onChange={handleOptionChange} className={styles.select}>
        {item?.id == 0
          ? timeZones?.map((item) => {
              return <option value={item?.value}>{item?.name}</option>;
            })
          : item?.options?.map((option) => {
              return <option>{option}</option>;
            })}
      </select>
    </div>
  );
};
