import { useState } from "react";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";

const accountSettingsTabs = [
  {
    id: 0,
    destination: "/dashboard/accountSettings/applicationSettings",
    title: "Application Settings",
  },
  {
    id: 1,
    destination: "",
    title: "Example",
  },
];

export const AccountSettingsSideMenu = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <ul className={styles.list}>
      {accountSettingsTabs.map((tab) => {
        return (
          <NavLink
            to={tab.destination}
            className={selectedTab === tab.id ? styles.activeTab : styles.tab}
            onClick={() => setSelectedTab(tab.id)}
          >
            {tab.title}
          </NavLink>
        );
      })}
    </ul>
  );
};
