import { AxiosResponse } from "axios";
import { ITopic, TopicFormState } from "models";
import { API } from "utils/interceptor";

export const deleteTopicApi = (id: string): Promise<AxiosResponse<ITopic>> => {
  return API.delete(`/topics/${id}`);
};

export const editTopicApi = (
  id: string,
  data: TopicFormState
): Promise<AxiosResponse<ITopic>> => {
  return API.patch(`/topics/${id}`, data);
};
