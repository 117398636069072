import React from "react";
import "./loading.css";

interface LoadingAnimationProps {
  isLoading?: boolean;
}

export const LineLoading: React.FC<LoadingAnimationProps> = () => {
  return (
    <>
        <div className="loading-animation">
          <div className="line"></div>
        </div>
    </>
  );
};
