import { AxiosError } from "axios";
import { IAxiosError } from "interface/model";
import { toast } from "react-toastify";

export const handleAxiosError = (data: AxiosError) => {
  const error = data.response?.data as IAxiosError;
  let messageError = "";
  if (error) messageError = error.errors?.[0] ?? error.message;
  else messageError = data.message;
  return messageError;
};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const updateUserValidation = (
  email: string,
  firstName: string,
  lastName: string
) => {
  if (email?.length == 0) {
    toast.error("Email is required");
    return false;
  } else if (firstName?.length == 0) {
    toast.error("First Name is required");
    return false;
  } else if (lastName?.length == 0) {
    toast.error("Last Name is required");
    return false;
  } else if (!validateEmail(email)) {
    toast.error("Email is not valid");
    return false;
  }

  return true;
};

export const passwordValidation = (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  if (oldPassword?.length == 0) {
    toast.error("Old Password is required");
    return false;
  } else if (newPassword?.length == 0) {
    toast.error("New Password is required");
    return false;
  } else if (confirmPassword?.length == 0) {
    toast.error("Confirm Password is required");
    return false;
  } else if (newPassword != confirmPassword) {
    toast.error("Passwords don't match");
    return false;
  }
  return true;
};

export const categoryValidation = (name: string, slug: string) => {
  if (name?.length == 0) {
    toast.error("Category Name is required");
    return false;
  } else if (slug?.length == 0) {
    toast.error("Slug is required");
    return false;
  }
  return true;
};

export const topicValidation = (name: string) => {
  if (name?.length == 0) {
    toast.error("Topic Name is required");
    return false;
  }
  return true;
};

export const mentorValidation = (
  name: string,
  job: string,
  email: string,
  overview: string
) => {
  if (name?.length == 0) {
    toast.error("Mentor Name is required");
    return false;
  } else if (job?.length == 0) {
    toast.error("Job is required");
    return false;
  } else if (email?.length == 0) {
    toast.error("Email is required");
    return false;
  } else if (!validateEmail(email)) {
    toast.error("Email is not valid");
    return false;
  } else if (overview?.length == 0) {
    toast.error("Overview is required");
    return false;
  }
  return true;
};

export const _careerFormValidation = (
  careerRequiredData: any,
  secondLangData: any
) => {
  if (!careerRequiredData.category) {
    toast.error("You have to select category to continue!");
    return false;
  } else if (!careerRequiredData.overView || !secondLangData.overView) {
    toast.error("Career Overview is required in both languages");
    return false;
  } else if (!careerRequiredData.name || !secondLangData.name) {
    toast.error("Career name is required!");
    return false;
  } else return true;
};
