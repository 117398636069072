import axios, { AxiosResponse } from "axios";
import { PaginationDto } from "dto";
import { ICareer, ICareerMember } from "models";
import { API } from "utils/interceptor";

export const getCareerApi = (
  paginationDto: PaginationDto
): Promise<AxiosResponse<ICareerMember>> => {
  return API.get(
    `/careers?sortType=ASC&page=${paginationDto?.page}&limit=${paginationDto?.limit}`
  );
};

export const deleteCareerApi = (
  id: string
): Promise<AxiosResponse<ICareer>> => {
  return API.delete(`/super-admin/careers/${id}`);
};
