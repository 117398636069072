import { IMAGES } from "assets/images";
import { RootState } from "interface/model";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.css";

interface Props {
  selectedImage: string;
  setSelectedImage: (selectedImage: string) => void;
}

export const UserImage: React.FC<Props> = ({
  selectedImage,
  setSelectedImage,
}) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setSelectedImage(URL.createObjectURL(fileObj));
  };

  return (
    <div className={styles.container}>
      <img
        src={selectedImage ? selectedImage : IMAGES.DEFAULT_IMAGE}
        className={styles.img}
      ></img>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
      />
      <img
        onClick={handleClick}
        src={IMAGES.UPLOAD_IMG}
        className={styles.circle}
      />

      <div>
        <div className={styles.name}>
          {user?.firstName + " " + user?.lastName}
        </div>
        <div className={styles.email}>{user?.email}</div>
      </div>
    </div>
  );
};
