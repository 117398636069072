import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import "./CustomAutocomplete.css";

type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  label: string;
  placeholder: string;
  optional?: boolean;
  addTags?: any;
  name?: string;
};

export const Tags = ({
  options,
  label,
  placeholder,
  name,
  optional,
  addTags,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const newFilteredOptions = options.filter((option) => {
      const isSelected = selectedOptions.some(
        (selectedOption) => selectedOption.value === option.value
      );
      return !isSelected;
    });
    setFilteredOptions(newFilteredOptions);
  }, [options, selectedOptions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("fired");
    const value = event.target.value;
    setInputValue(value);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowDropdown(true);
  };

  const handleTagClick = (option: Option) => {
    const newSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption.value !== option.value
    );
    setSelectedOptions(newSelectedOptions);

    const newFilteredOptions = [...filteredOptions, option];
    setFilteredOptions(newFilteredOptions);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && !inputValue) {
      const newSelectedOptions = selectedOptions.slice(0, -1);
      setSelectedOptions(newSelectedOptions);
    } else if (event.key === "Enter") {
      const option = filteredOptions.find(
        (option) => option.label.toLowerCase() === inputValue.toLowerCase()
      );
      if (option) {
        setSelectedOptions([...selectedOptions, option]);
        setInputValue("");
        setShowDropdown(false);
      }
    }
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOptions([...selectedOptions, option]);
    addTags(selectedOptions);
    console.log('selected options:',selectedOptions)
    setInputValue("");
    setShowDropdown(false);
    const areas = [...selectedOptions, option].map((option) => {
      return option.value;
    });
    // add params to avoid session.
    sessionStorage.setItem("areas", JSON.stringify(areas));
  };

  return (
    <div className="autocomplete-container" ref={containerRef}>
      <label htmlFor={placeholder}>
        {label}{" "}
        {optional && <small style={{ color: "#ccc" }}>(optional)</small>}
      </label>
      <div className="tags-container" onClick={() => setShowDropdown(true)}>
        {selectedOptions.map((option) => (
          <div key={option.value} className="tag">
            {option.label}
            <button
              className="remove-button"
              onClick={() => handleTagClick(option)}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          name={name}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={placeholder}
          className="input"
        />
      </div>
      {showDropdown && filteredOptions.length > 0 && (
        <ul className="options-list">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="option"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
