import { getToken } from "firebaselint";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFcm } from "state/slices/fcm";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();
  let data;
  async function tokenFunc() {
    data = await getToken(setTokenFound);
    if (data) {
      console.log("Token is", data);
      dispatch(setFcm(data));
    }
    return data;
  }

  // To load once
  useEffect(() => {
    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
