import { IItemOverviewCard } from "models";
import React from "react";
import styles from "./style.module.css";

type IProps = {
  item: IItemOverviewCard;
};

export const DetailCard: React.FC<IProps> = ({ item }) => {
  const formattedCount = item?.count;
  return (
    <div className={styles.card}>
      <div className={styles.details}>
        <div className={styles.imgWrapper}>
          <img src={item?.imgPath} width={25} />
        </div>
        <p className={styles.count}>{formattedCount}</p>
        <p className={styles.title}>{item?.title}</p>
      </div>
      <p
        style={
          item?.percentage.includes("-") ? { color: "red" } : { color: "blue" }
        }
      >
        {item?.percentage}
      </p>
    </div>
  );
};
