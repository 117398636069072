import { CircularProgress } from "@mui/material";
import { IMAGES } from "assets/images";
import { AdminHeader, ArrowBack, LanguageTabs } from "components";
import { useAxios } from "hooks";
import {
  CategoryFormState,
  CategorySecondFormState,
  ICategory,
  ICategoryItem,
  MentorFormState,
  MentorSecondFormState,
} from "models";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import { categoryValidation, mentorValidation } from "utils/helperFun";
import styles from "./style.module.css";

const AddMentor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let params = location.state as { item: ICategory };
  const [selectedTab, setSelectedTab] = useState(1);
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [formState, setFormState] = useState<MentorFormState>({
    name: "",
    overview: "",
    email: "",
    lang: "",
    job: "",
  });

  const [formStateSecond, setFormStateSecond] = useState<MentorSecondFormState>(
    {
      name: "",
      overview: "",
      lang: "",
      job: "",
    }
  );

  const { loading, method: _add } = useAxios<ICategoryItem>({
    url: `/mentor/with-multi-lang`,
    method: "post",
  });

  const handleChangeEnglish = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormStateSecond((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      mentorValidation(
        formState.name,
        formState.job,
        formState.email,
        formState.overview
      ) &&
      mentorValidation(
        formStateSecond.name,
        formStateSecond.job,
        formState.email,
        formStateSecond.overview
      )
    ) {
      formState.lang = "en";
      formStateSecond.lang = selectedTab == 1 ? "ar" : "fr";
      let multiLangData = [];
      multiLangData.push(formState, formStateSecond);
      _add({
        data: {
          multiLangData,
        },
        isShowErrorToast: true,
        successMessage: "Mentor added successfully",
        Toast: true,
      }).then((_) => {
        navigate("/dashboard/mentors");
      });
      console.log({ formState, formStateSecond, multiLangData });
    }
  };

  return (
    <div className={styles.container}>
      <AdminHeader />

      <div className={styles.card}>
        <ArrowBack link="/dashboard/mentors" />

        <div style={{ flexDirection: "row", display: "flex" }}>
          {MOCK_DATA.languageTabs?.map((item, index) => {
            return (
              <LanguageTabs
                dataTabs={dataTabs}
                item={item}
                tabIndex={index}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setDataTabs={setDataTabs}
              />
            );
          })}
        </div>
        <div className={styles.title}>{"Add Mentor"}</div>
        <div className={styles.subTitle}>
          {"Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."}
        </div>

        <div className={styles.grid}>
          <div className={`${styles.englishForm}`}>
            <div className={styles.col}>
              <img
                src={IMAGES.UK_FLAG}
                alt="uk flag"
                width={20}
                style={{ marginBlock: "2px" }}
              />

              <div>
                {MOCK_DATA.MentorFormInputs.map((formInput, formIndex) => {
                  return (
                    <div>
                      <div style={{ height: 12 }} />
                      <label className={styles.label}>
                        {formInput.label}
                        {formInput.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>

                      <textarea
                        key={formIndex.toString()}
                        className={styles.area}
                        style={{
                          resize: "none",
                        }}
                        placeholder={formInput.placeholder}
                        required={formInput.required}
                        name={formInput.name}
                        defaultValue={formIndex == 0 ? params?.item?.name : ""}
                        onChange={(e) => {
                          handleChangeEnglish(e);
                        }}
                        cols={1}
                        rows={formInput?.id == 4 ? 4 : 1}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.cardInput}>
            <div className={styles.col}>
              <div className={styles.englishForm}>
                <img
                  src={selectedTab == 1 ? IMAGES.EG_FLAG : IMAGES.FR_FLAG}
                  alt="uk flag"
                  width={20}
                  style={{ marginBlock: "2px" }}
                />

                {MOCK_DATA.MentorSecondFormInputs.map(
                  (formInput, formIndex) => {
                    return (
                      <div key={formIndex?.toString()}>
                        <div style={{ height: 12 }} />
                        <label className={styles.label}>
                          {formInput.label}
                          {formInput.required && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>

                        <textarea
                          key={formIndex.toString()}
                          className={styles.area}
                          style={{
                            resize: "none",
                          }}
                          placeholder={formInput.placeholder}
                          required={formInput.required}
                          name={formInput.name}
                          defaultValue={
                            formIndex == 0 ? params?.item?.name : ""
                          }
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          cols={1}
                          rows={formInput?.id == 3 ? 4 : 1}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rowBtn}>
          <button type="submit" className={styles.cancel} disabled={false}>
            <div onClick={() => {}} className={styles.cancelTxt}>
              {"Cancel"}
            </div>
          </button>
          <button
            type="submit"
            onClick={(e: any) => handleSubmit(e)}
            className={styles.submit}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <div className={styles.submitTxt}>{"Save"}</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMentor;
