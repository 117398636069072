import { IMAGES } from "assets/images";
import { Alert } from "components/alert";
import React, { useState } from "react";
import styles from "./style.module.css";
import { Switch, SwitchProps } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAxios } from "hooks";
import { IUser } from "models";

interface Props {
  item?: IUser;
  onDelete?: () => void;
  onDownLoad?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  uploadIcon?: boolean;
  checked?: boolean;
  loading?: boolean;
  isActiveFlag?: string;
  isToggle?: boolean;
  isDelete?: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#ccc",
    },
  },
});

const Actions: React.FC<Props> = ({
  onEdit,
  onDelete,
  onDownLoad,
  onClose,
  uploadIcon = false,
  checked,
  loading,
  isActiveFlag,
  item,
  isToggle = true,
  isDelete = true,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState<SwitchProps["color"]>("primary");
  const [isChecked, setIsChecked] = useState(false);
  const [thumbColor, setThumbColor] = useState("#1c1c1c");
  const [isActive, setIsActive] = useState(checked);

  const { method: editUser } = useAxios<IUser>({
    url: `/super-admin/dashboard/users/${item?.id}`,
    method: "patch",
  });

  const onSubmit = (value: boolean) => {
    editUser({
      isShowErrorToast: true,
      Toast: true,
      successMessage: value
        ? "User activated successfully"
        : `User disactivated successfully`,
      data: {
        isActivated: value,
      },
    });
  };

  const handleChange = (event: { target: { checked: any } }) => {
    setColor(event.target.checked ? "secondary" : "primary");
    setThumbColor(event.target.checked ? "#1c1c1c" : "#777");
    console.log(event.target.checked);
    setIsActive(event.target.checked);
    if (isActiveFlag == "user") {
      onSubmit(event.target.checked);
    } else {
    }
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      <div className={styles.row}>
        {isDelete && (
          <img
            onClick={() => {
              setIsOpen(true);
            }}
            src={IMAGES.DELETE}
            className={styles.icon}
          />
        )}

        {uploadIcon && (
          <img
            onClick={onDownLoad}
            src={IMAGES.UPLOAD}
            className={styles.icon}
          />
        )}
        <img onClick={onEdit} src={IMAGES.EDIT} className={styles.icon} />

        {isToggle ? (
          <ThemeProvider theme={theme}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                color="primary"
                onChange={handleChange}
                defaultChecked={isActive}
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#A9A9A9",
                    width: "18px",
                    height: "18px",
                    boxShadow: "0px 0px 2px #333",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: 0,
                      borderRadius: 0,
                      boxShadow: "0px 0px 4px #333",
                    },
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#ccc",
                    opacity: 1,
                    height: "14px",
                  },
                  "& .Mui-checked .MuiSwitch-thumb": {
                    // transform: "translateX(5px)",
                    backgroundColor: "#000",
                  },
                  "& .Mui-checked .MuiSwitch-track": {
                    backgroundColor: "#ccc",
                  },
                }}
              />
            </div>
          </ThemeProvider>
        ) : (
          <div className={styles.icon} />
        )}
        <Alert
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          loading={loading}
          title={"Are you sure? you want to delete this?"}
          description="LoEm dolor sit amet consectetur"
          onPress={() => {
            onDelete();
            setIsOpen(false);
            // onClose();
          }}
          btnTitle={"Done"}
          image={IMAGES.ALERT}
          isTwoBtn={true}
          cancelTitle={"No,Keep"}
        />
      </div>
    </div>
  );
};

export default Actions;
