import { CircularProgress } from "@mui/material";
import { IMAGES } from "assets/images";
import { AdminHeader, ArrowBack, LanguageTabs } from "components";
import { useAxios } from "hooks";
import {
  CategoryFormState,
  CategorySecondFormState,
  ICategory,
  ICategoryItem,
} from "models";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import { categoryValidation } from "utils/helperFun";
import styles from "./style.module.css";

const AddCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let params = location.state as { item: ICategory };
  const [selectedTab, setSelectedTab] = useState(1);
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [formState, setFormState] = useState<CategoryFormState>({
    name: "",
    description: "",
    slug: "",
    lang: "",
  });
  const [formStateSecond, setFormStateSecond] =
    useState<CategorySecondFormState>({
      name: "",
      description: "",
      lang: "",
    });

  const { loading, method: _add } = useAxios<ICategoryItem>({
    url: `/category/with-multi-lang`,
    method: "post",
  });

  const handleChangeEnglish = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormStateSecond((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      categoryValidation(formState.name, formState.slug) &&
      categoryValidation(formStateSecond.name, formState.slug)
    ) {
      formState.lang = "en";
      formStateSecond.lang = selectedTab == 1 ? "ar" : "fr";
      let multiLangData = [];
      delete formState?.slug;
      multiLangData.push(formState, formStateSecond);
      _add({
        data: {
          multiLangData,
          slug: formState?.slug,
        },
        isShowErrorToast: true,
        successMessage: "Category added successfully",
        Toast: true,
      }).then((_) => {
        navigate("/dashboard/categories");
      });
      console.log({ formState, formStateSecond, multiLangData });
    }
  };

  console.log("PARAMS : ------->>>> ");

  console.log(params?.item);

  return (
    <div className={styles.container}>
      <AdminHeader />

      <div className={styles.card}>
        <ArrowBack link="/dashboard/categories" />

        <div style={{ flexDirection: "row", display: "flex" }}>
          {MOCK_DATA.languageTabs?.map((item, index) => {
            return (
              <LanguageTabs
                dataTabs={dataTabs}
                item={item}
                tabIndex={index}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setDataTabs={setDataTabs}
              />
            );
          })}
        </div>
        <div className={styles.title}>{"Add category"}</div>
        <div className={styles.subTitle}>
          {"Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."}
        </div>

        <div className={styles.grid}>
          <div>
            <div className={styles.col}>
              <img
                src={IMAGES.UK_FLAG}
                alt="uk flag"
                width={20}
                style={{ marginBlock: "2px" }}
              />

              <div>
                {MOCK_DATA.CategoryFormInputs.map((formInput, formIndex) => {
                  return (
                    <>
                      <div style={{ height: 12 }} />
                      <label className={styles.label}>
                        {formInput.label}
                        {formInput.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>

                      <textarea
                        key={formIndex.toString()}
                        className={styles.area}
                        style={{
                          resize: "none",
                        }}
                        placeholder={formInput.placeholder}
                        required={formInput.required}
                        name={formInput.name}
                        defaultValue={formIndex == 0 ? params?.item?.name : ""}
                        onChange={(e) => {
                          handleChangeEnglish(e);
                        }}
                        cols={1}
                        rows={1}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.cardInput}>
            <div className={styles.col}>
              <img
                src={selectedTab == 1 ? IMAGES.EG_FLAG : IMAGES.FR_FLAG}
                alt="uk flag"
                width={20}
                style={{ marginBlock: "2px" }}
              />

              {MOCK_DATA.CategorySecondFormInputs.map(
                (formInput, formIndex) => {
                  return (
                    <div key={formIndex?.toString()}>
                      <div style={{ height: 12 }} />
                      <label className={styles.label}>
                        {formInput.label}
                        {formInput.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>

                      <textarea
                        key={formIndex.toString()}
                        className={styles.area}
                        style={{
                          resize: "none",
                        }}
                        placeholder={formInput.placeholder}
                        required={formInput.required}
                        name={formInput.name}
                        defaultValue={formIndex == 0 ? params?.item?.name : ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        cols={1}
                        rows={1}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>

        <div className={styles.rowBtn}>
          <button type="submit" className={styles.cancel} disabled={false}>
            <div onClick={() => {}} className={styles.cancelTxt}>
              {"Cancel"}
            </div>
          </button>
          <button
            type="submit"
            onClick={(e: any) => handleSubmit(e)}
            className={styles.submit}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <div className={styles.submitTxt}>{"Save"}</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
