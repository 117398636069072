import { IMAGES } from "assets/images";
import { AdminHeader, LineLoading, UsersDataGrid } from "components";
import Actions from "components/actions";
import FilterHeader from "components/filterHeader";
import Loading from "components/loading";
import NotFound from "components/notFound";
import { ROLE } from "interface/model";
import { IUser } from "models";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllMembersApi } from "services";
import styles from "./style.module.css";
import Highlighter from "react-highlight-words";

let page = 1;
let limit = 10;

export const Users = () => {
  const [members, setMembers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState<string>("");

  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  const navigation = useNavigate();

  useEffect(() => {
    _getAllUsers(1);
  }, []);

  const _getAllUsers = (page: number) => {
    setLoading(true);
    getAllMembersApi({ page, limit })
      .then((res) => {
        console.log(res);
        setMembers(res.data?.data);
        setPaginationData({
          currentPage: res?.data?.currentPage,
          itemsPerPage: res?.data?.itemsPerPage,
          totalItems: res?.data?.totalItems,
          totalPages: res?.data?.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <AdminHeader
        placeholder={"Search by name ..."}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
      <div className={styles.center}>
        <FilterHeader label="Users List" onImport={() => {}} isFilter={false} />
        <UsersDataGrid />
      </div>
    </div>
  );
};

export default Users;
