import { SideMenu } from "components";
import CSS from "csstype";
import { Outlet } from "react-router-dom";

export const Dashboard = () => {
  return (
    <div style={style.container}>
      <SideMenu />
      <div style={style.div}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;

const style: { [key: string]: CSS.Properties } = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    maxHeight: "100vh",
    overflow: "hidden",
    paddingRight: "20px",
  },
  div: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
};
