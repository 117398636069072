import { AdminHeader, ArrowBack } from "components";
import { IUser } from "models";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import { useAxios } from "hooks";
import { CircularProgress } from "@mui/material";

export const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let params = location.state as { item: IUser };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const isDisabled =
    firstName == params?.item?.firstName &&
    lastName == params?.item?.lastName &&
    phone == params?.item?.phone;

  const { loading, method: editUser } = useAxios<IUser>({
    url: `/super-admin/dashboard/users/${params?.item?.id}`,
    method: "patch",
  });

  useEffect(() => {
    setFirstName(params?.item?.firstName);
    setEmail(params?.item?.email);
    setLastName(params?.item?.lastName);
    setPhone(params?.item?.phone);
  }, []);

  const onSubmit = () => {
    let data = {
      firstName,
      lastName,
      phone,
    };
    editUser({
      isShowErrorToast: true,
      Toast: true,
      successMessage: "User edited successfully",
      data,
    });
  };

  return (
    <div>
      <AdminHeader />
      <div className={styles.container}>
        <ArrowBack link="/dashboard/users" />
        <div className={styles.editContainer}>
          <h3 className={styles.title}>Edit User</h3>
          <small className={styles.subtitle}>
            Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu.
          </small>
          <form
            className={styles.editUserForm}
            onSubmit={(e) => {
              e.preventDefault();
              if (!isDisabled) onSubmit();
            }}
          >
            <input
              type="email"
              placeholder={"assess@mail.com"}
              disabled
              value={email}
              onChange={(e) => {
                setEmail(e?.target?.value);
              }}
            />
            <input
              type="text"
              placeholder="first name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e?.target?.value);
              }}
              required
            />
            <input
              type="text"
              placeholder="last name"
              value={lastName}
              onChange={(e) => {
                setLastName(e?.target?.value);
              }}
              required
            />
            <input
              type="text"
              placeholder="phone"
              value={phone}
              required
              onChange={(e) => {
                setPhone(e.target.value.replace(/\D/g, ""));
              }}
            />
            <div className={styles.btnWrapper}>
              <button
                className={isDisabled ? styles.buttonDisabled : styles.button}
                type="submit"
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={20} />
                ) : (
                  <div className={styles.btnTxt}>Save</div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
