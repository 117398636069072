import { ICategory } from "./../../models/category";
import { ICareer, IUser } from "models";
import { IAddCareer } from "dto/career";

export interface IItemPassword {
  id: number;
  name: string;
}

export interface IAxiosError {
  errors: string[];
  message: string;
  statusCode: number;
}

export enum VideosStatus {
  APPROVED = "Approved",
  IN_REVIEW = "In review",
  REJECTED = "Rejected",
  REQUEST_MODIFICATION = "Request modification",
}

export enum ROLE {
  MEMBER = "MEMBER",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  COACH = "COACH",
}

export interface RootState {
  userState: {
    user: IUser;
  };
  careerState: {
    career: ICareer;
  };
  categoryState: {
    category: ICategory;
  };
  fcmState: {
    fcmToken: string;
  };
  addCareerState: {
    addCareer: IAddCareer;
  };
}

export interface ISimpleList {
  id: string;
  name: string;
  image: string;
}

export interface ITopicList {
  lang: string;
  text: string;
}
