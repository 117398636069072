import React, { FormEvent, useState } from "react";
import styles from "./style.module.css";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "assets/images";
import { VideosStatus } from "interface/model";
import { IVideo } from "models/videos";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (modalIsOpen: boolean) => void;
  item: any;
}

export const VideoApproval: React.FC<Props> = ({
  setIsOpen,
  modalIsOpen,
  item,
}) => {
  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.container}>
        <div className={styles.closeDiv}>
          <button className={styles.btnClose} onClick={closeModal}>
            <img src={IMAGES.CLOSEICON} className={styles.close} />
          </button>
        </div>

        {/* <div className={styles.background}>
          <button type="submit" onClick={() => {}} className={styles.btnPlay}>
            <img src={IMAGES.PLAY} className={styles.play} />
          </button>
        </div> */}

        <video className={styles.background} controls autoPlay={false}>
          <source
            src="http://techslides.com/demos/sample-videos/small.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>

        <div className={styles.title}>{item?.name[0]?.text}</div>
        <div className={styles.rowName}>
          <div className={styles.row}>
            <img src={IMAGES.PROFILE_HOLDER} className={styles.profile} />
            <div className={styles.name}>{"Amr Essam"}</div>
          </div>
          <div
            className={
              // item.status == VideosStatus.APPROVED
              //   ? styles.approved
              //   : item.status == VideosStatus.REJECTED
              //   ? styles.rejected
              //   : item.status == VideosStatus.IN_REVIEW
              //   ? styles.review
              //   : styles.modify
              styles.approved
            }
          >
            {/* {item.status} */}
            Approved
          </div>
        </div>

        <div className={styles.description}>{item?.description[0]?.text}</div>

        <div className={styles.rowName}>
          <div className={styles.col}>
            <div className={styles.title}>{"Upload date"}</div>
            <div className={styles.date}>{"22/2/2023"}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.title}>{"Duration"}</div>
            <div className={styles.date}>{"30 min"}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.title}>{"Created by "}</div>
            <div className={styles.date}>{"Admin"}</div>
          </div>
        </div>

        <div style={{ height: 40 }} />

        <div style={{ backgroundColor: "#F2F3F4", borderRadius: 8 }}>
          <div className={styles.card}>
            <div className={styles.approval}>{"Video approval request"}</div>
            <div className={styles.row}>
              <img src={IMAGES.CHECK} className={styles.check} />
              <img src={IMAGES.RED_CLOSE} className={styles.check} />
            </div>
          </div>
        </div>

        {/* Text Area      */}
        <div className={styles.title}>{"Video rejection reasons"}</div>
        <textarea
          style={{
            resize: "none",
            padding: 12,
            borderColor: "#DCDCDC",
            borderRadius: 8,
            marginTop: 12,
            backgroundColor: "transparent",
            width: "100%",
          }}
          cols={4}
          rows={4}
          placeholder="Write here the reason for your rejection of the video ...."
        />
        <button type="submit" onClick={() => {}} className={styles.submit}>
          <div className={styles.submitTxt}>{"Send"}</div>
        </button>
      </div>
    </Modal>
  );
};
