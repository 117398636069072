import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/animationData.json";
import styles from "./style.module.css";

interface Props {
  simpleLoading?: boolean;
}

const Loading: React.FC<Props> = ({ simpleLoading = false }) => {
  // const defaultOptions = {
  //   loop: true,
  //   animationData: animationData,
  //   autoplay: true,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  return simpleLoading ? (
    <Lottie
      animationData={animationData}
      loop={true}
      autoplay={true}
      height={10}
      width={10}
    />
  ) : (
    <div className={styles.card}>
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        height={20}
        width={20}
      />
    </div>
  );
};

export default Loading;
