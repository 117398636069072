import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styles from "./style.module.css";
import { Typography } from "@mui/material";
import Actions from "components/actions";
import { DATAGRIDSTYLE } from "utils";
import { ITopic } from "models";
import { deleteTopicApi } from "services";
import { toast } from "react-toastify";
import { useAxios } from "hooks";
import { useNavigate } from "react-router-dom";
import {
  CustomNoRowsOverlay,
  CustomLoadingOverlay,
} from "components/shared/datagridOverlays/customOverlays";

export const TopicsDataGrid: React.FC = () => {
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const navigation = useNavigate();

  const { loading, method: getTopics } = useAxios<ITopic[]>({
    url: `/topics/all`,
    method: "get",
  });

  useEffect(() => {
    _getAll();
  }, []);

  const _getAll = () => {
    getTopics().then((res: any) => {
      setTopics(res?.data);
    });
  };

  const _delete = (id: string) => {
    setDeleteLoading(true);
    deleteTopicApi(id)
      .then((_) => {
        toast.success("Topic deleted Successfully");
      })
      .then((_) => {
        setDeleteLoading(false);
        _getAll();
      })
      .catch((e) => {
        setDeleteLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Topic Name",
      flex: 1,
      headerClassName: styles.title,
      renderCell: ({ row: { name } }) => {
        return (
          <Typography>
            {name[0]?.text !== undefined ? name[0]?.text : "First topic"}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: styles.title,
      flex: 1,
      renderCell: ({ row: { description } }) => {
        return (
          <Typography>
            {description[0]?.text !== undefined
              ? description[0]?.text
              : "First topic"}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      flex: 1,
      headerClassName: styles.title,
      disableColumnMenu: true,
      renderCell: ({ row, row: { id } }) => (
        <Actions
          isToggle={false}
          onEdit={() => {
            navigation("/dashboard/addTopic", {
              state: {
                edit: true,
                item: row,
              },
            });
          }}
          loading={deleteLoading}
          onDelete={() => {
            _delete(id);
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      {!loading && (
        <DataGrid
          sx={DATAGRIDSTYLE}
          rows={topics}
          columns={columns}
          autoPageSize
          checkboxSelection={true}
          components={{
            NoRowsOverlay: () =>
              CustomNoRowsOverlay(
                "No videos found",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries"
              ),
            LoadingOverlay: CustomLoadingOverlay,
          }}
          // paginationMode="server"
          keepNonExistentRowsSelected
        />
      )}
    </div>
  );
};
