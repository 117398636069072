import { IMAGES } from "assets/images";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";

const renderLogo = () => (
  <img
    src={IMAGES.LOGO}
    alt="Brand Logo"
    title="assess"
    className={styles.logo}
  />
);

const renderIcon = (icon: string) => (
  <img src={icon} width={20} height={20} alt="icon" />
);

export const SideMenu = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [subNav, setSubNav] = useState<boolean>(false);
  const [selectedSubTab, setSelectedSubTab] = useState<number>(0);

  useEffect(() => {
    const storedTab = parseInt(localStorage.getItem("selectedTab"));
    const subStatus = localStorage.getItem("subNavStatus");
    if (!isNaN(storedTab)) {
      setSelectedTab(storedTab);
    }
    setSubNav(Boolean(subStatus));
  }, []);

  const handleSelectedTab = (index: number) => {
    setSelectedTab(index);
    localStorage.setItem("selectedTab", index.toString());
    //TODO: add selected subNav to localStorage.
    // localStorage.setItem("selectedSubTab", index.toString());
  };

  const handleSelectedSubTab = (index: number) => {
    setSelectedSubTab(index);
    localStorage.setItem("selectedSubTab", index.toString());
  };

  const handleSubNav = () => setSubNav(!subNav);

  const spacer = <span style={{ width: "12px" }} />;
  return (
    <div className={styles.container}>
      <div
        onClick={() => {
          localStorage.setItem("selectedTab", "0");
        }}
        className={styles.logoWrapper}
      >
        <NavLink to="/">{renderLogo()}</NavLink>
      </div>
      {MOCK_DATA.tabs.map((tab) => {
        return (
          <div key={tab.id}>
            {!tab.subNav ? (
              <NavLink
                to={tab.destination}
                className={`${styles.tab} ${
                  selectedTab === tab.id ? styles.activeTab : ""
                }`}
                onClick={() => {
                  handleSelectedTab(tab.id);
                }}
                key={tab.id}
              >
                <div className={styles.iconWithTitle}>
                  {renderIcon(
                    `${
                      selectedTab === tab.id
                        ? tab.imgPathActive
                        : tab.imgPathNotActive
                    }`
                  )}
                  {spacer}
                  <div
                    className={`${styles.title} ${
                      selectedTab === tab.id ? styles.active : ""
                    }`}
                  >
                    {tab.title}
                  </div>
                </div>
              </NavLink>
            ) : (
              <NavLink
                to={tab.destination}
                onClick={() => {
                  if (!subNav) {
                    handleSubNav();
                    handleSelectedTab(tab.id);
                    handleSelectedSubTab(0);
                  } else if (subNav && tab.id === selectedSubTab) {
                    setSubNav(false);
                  } else {
                    handleSelectedTab(tab.id);
                    handleSelectedSubTab(0);
                  }
                }}
                key={tab.id}
                className={`${styles.subNavBtn} ${styles.tab} ${
                  selectedTab === tab.id && styles.activeTab
                }`}
              >
                <div className={styles.iconWithTitle}>
                  {renderIcon(
                    `${
                      selectedTab === tab.id
                        ? tab.imgPathActive
                        : tab.imgPathNotActive
                    }`
                  )}
                  {spacer}
                  <div
                    className={`${styles.title} ${
                      selectedTab === tab.id ? styles.active : ""
                    }`}
                  >
                    {tab.title}
                  </div>
                </div>
                {tab.subNav && subNav && tab.id === selectedTab ? (
                  <img
                    src={IMAGES.ARROW_UP_Active}
                    className={styles.arrowIcon}
                    width={12}
                  />
                ) : tab.subNav ? (
                  <img
                    src={IMAGES.ARROW_DOWN}
                    className={styles.arrowIcon}
                    width={12}
                  />
                ) : null}
              </NavLink>
            )}
            {tab.subNav && subNav && tab.id === selectedTab
              ? tab.subNav.map((item) => (
                  <NavLink
                    to={item.path}
                    onClick={() => {
                      handleSelectedSubTab(item.id);
                    }}
                    className={`${styles.dropdownLink} ${
                      selectedSubTab === item.id && styles.dropdownLinkActive
                    }`}
                    key={item.id}
                  >
                    {item.label}
                  </NavLink>
                ))
              : null}
          </div>
        );
      })}
    </div>
  );
};
