import { AdminHeader, TopicsDataGrid } from "components";
import FilterHeader from "components/filterHeader";
import {  useState } from "react";
import styles from "./style.module.css";
import Highlighter from "react-highlight-words";

export const Topics = () => {
  const [searchKey, setSearchKey] = useState<string>("");

  return (
    <div className={styles.container}>
      <AdminHeader
        placeholder="Search by name ..."
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />

      <div className={styles.center}>
        <FilterHeader
          label="Topics"
          isAdd
          destination={"/dashboard/addTopic"}
          onImport={() => {}}
        />
        <TopicsDataGrid />
      </div>
    </div>
  );
};

export default Topics;
