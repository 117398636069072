import styles from "./style.module.css";

type Props = {
  labelText: string;
  id: string;
  isRequired: boolean;
  isBig?: boolean;
  placeholder?: string;
  onChange?: (e: any) => void | undefined;
};

export const CustomFormInput = ({
  labelText,
  id,
  isRequired,
  isBig = false,
  placeholder = "",
  onChange
}: Props) => {
  return (
    <div className={styles.labelAndInput}>
      <label htmlFor={id}>
        {labelText}
        {isRequired ? (
          <span style={{ color: "red" }}>*</span>
        ) : (
          <small style={{ color: "gray", marginLeft: "5px" }}>(optional)</small>
        )}
      </label>
      <textarea
        className={isBig ? styles.bigTextarea : styles.normalTextarea}
        style={{ resize: "none" }}
        cols={4}
        rows={4}
        id={id}
        placeholder={placeholder}
        required={isRequired}
        onChange= {onChange}
      />
    </div>
  );
};
