export const injectStyle = (style: string) => {
  const styleElement = document.createElement("style");
  let styleSheet: CSSStyleSheet | null;

  document.head.appendChild(styleElement);

  styleSheet = styleElement.sheet;

  styleSheet?.insertRule(style, styleSheet?.cssRules.length);
};

export const DATAGRIDSTYLE = {
  color: "#fff",
  border: 0,
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#FBFBFC",
    color: "#fff",
  },
  "& .css-jkymtx-MuiPaper-root": {
    backgroundColor: "#fff !important",
    "& span": {
      color: "#fff",
    },
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#FBFBFC",
  },
  "& .MuiDataGrid-cell-selected": {
    color: "#fff",
  },
  "& .MuiDataGrid-menuIcon": {
    alignItems: "left",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    height: "100%",
    background: "#fff",
    width: "100%",
    // boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",
    //  marginTop: "8px",
  },
  "& .MuiDataGrid-columnsContainer": {
    height: "100%",
    border: "none",
    outline: "none",
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0",
    border: "0",
  },
  "& .css-1ptahwm-MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-root": {
    border: "none",
    borderStyle: "none",
    outline: "none",
  },
  "& .MuiMenu-paper": {
    color: "#fff",
    "& span": {
      color: "#fff",
    },
  },
  "& .css-1w53k9d-MuiDataGrid-overlay": {
    backgroundColor: "#fff",
  },
};
