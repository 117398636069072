import styles from "./style.module.css";
import {
  AddCareer,
  AddDetails,
  AddTasks,
  AdminHeader,
  ArrowBack,
} from "components";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import { Box, Breadcrumbs } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useBreadcrumbForm } from "hooks/useBreadcrumbForm";
import { useAxios } from "hooks";
import { toast } from "react-toastify";
import { _careerFormValidation } from "utils/helperFun";

export const CustomBreadcrumb = () => {
  const { step, next, goTo } = useBreadcrumbForm([
    <AddCareer />,
    <AddDetails />,
    <AddTasks />, // ask UI ?
  ]);

  //states ..
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [activeTabs, setActiveTabs] = useState(["Add career"]);
  const navigate = useNavigate();
  const { breadcrumbTabs } = MOCK_DATA;

  // Methods ..
  const handleTabClick = (label: string) => {
    const index = breadcrumbTabs.findIndex((tab) => tab.label === label);
    const newSteps = breadcrumbTabs.slice(0, index + 1).map((tab) => tab.label);
    setActiveTabs(newSteps);
    goTo(index);
  };

  const currentIndex = breadcrumbTabs.findIndex(
    (tab) => tab.label === activeTabs[activeTabs.length - 1]
  );

  const { method: postNewCareerWithMultiLang } = useAxios({
    url: "/super-admin/careers/with-multi-lang",
    method: "post",
  });

  const addNewCareerWithMuliLang = (newCareerWithMultiLang: any) => {
    console.log(newCareerWithMultiLang);
    let category = newCareerWithMultiLang[1]?.category;
    delete newCareerWithMultiLang[1]?.category;
    const areas = sessionStorage.getItem("areas");
    const areasArr = JSON.parse(areas);
    postNewCareerWithMultiLang({
      data: {
        multiLangData: newCareerWithMultiLang,
        category: category,
        areas: areasArr,
        salaries: [15000, 30000, 50000],
      },
      successMessage: "Career Data with multi languages has been added",
      isShowErrorToast: true,
      Toast: true,
    })
      .then((res) => console.log(res.config.data))
      .catch((err) => console.log(err.response));
  };

  const handleNext = () => {
    const careerRequiredData: any = JSON.parse(
      sessionStorage.getItem("addCareer")
    );

    const secondLangData: any = JSON.parse(
      sessionStorage.getItem("addCareerMultiLang")
    );

    if (_careerFormValidation(careerRequiredData, secondLangData)) {
      if (currentIndex < breadcrumbTabs.length - 1) {
        const nextStep = breadcrumbTabs[currentIndex + 1];
        setActiveTabs([...activeTabs, nextStep.label]);
        next();
        navigate(nextStep.destination);
      } else {
        navigate("/dashboard/careers");
      }
    } else {
      toast.error("check your data, please");
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleNext();
    const careerData = sessionStorage.getItem("addCareer");
    const multiLang = sessionStorage.getItem("addCareerMultiLang");
    const newCareerWithMultiLang = [
      JSON.parse(multiLang),
      JSON.parse(careerData),
    ];

    if (newCareerWithMultiLang) {
      addNewCareerWithMuliLang(newCareerWithMultiLang);
    }

    //remove Session Data.
    sessionStorage.removeItem("addCareer");
    sessionStorage.removeItem("careerDetails");
    sessionStorage.removeItem("addCareerMultiLang");
  };

  useEffect(() => {
    setDataTabs(
      dataTabs.filter((item, index) => {
        return index == 0 || index == 1;
      })
    );
  }, []);

  return (
    <>
      <AdminHeader />
      <div className={styles.breadcrumbContainer}>
        <div>
          <ArrowBack link="/dashboard/careers" />
        </div>

        <Box>
          <Breadcrumbs
            className={styles.breadcrumbPages}
            aria-label="breadcrumbs"
            separator={
              <NavigateNext
                fontSize="small"
                style={{ color: "#d2d2d2", margin: 0 }}
              />
            }
          >
            {breadcrumbTabs.map((tab) => {
              return (
                <Link
                  className={`${styles.breadcrumbTab} ${
                    activeTabs.includes(tab.label)
                      ? styles.active_
                      : styles.disabled
                  }`}
                  key={tab.id}
                  to={tab.destination}
                  onClick={() => {
                    handleTabClick(tab.label);
                  }}
                >
                  {tab.label}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
        {step}
        <div className={styles.actionButtons}>
          <Link
            to="/dashboard/careers"
            className={styles.cancel}
            onClick={() => {
              sessionStorage.removeItem("addCareer");
              sessionStorage.removeItem("addCareerMultiLang");
              sessionStorage.removeItem("careerDetails");
            }}
          >
            Cancel
          </Link>

          <button
            type="button"
            onClick={
              currentIndex < breadcrumbTabs.length - 1
                ? handleNext
                : handleSubmit
            }
            className={styles.next}
          >
            {currentIndex < breadcrumbTabs.length - 1 ? "Next" : "Finish"}
          </button>
        </div>
      </div>
    </>
  );
};
