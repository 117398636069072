import { BreadcrumbChildHeader, LanguageTabs } from "components";
import { FormState, ICareer, ICategory } from "models";
import { useEffect, useState } from "react";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";
import { useAxios } from "hooks";
import { useLocation } from "react-router-dom";

export const AddCareer = () => {
  // const location = useLocation();
  // let params = location.state as { career: ICareer };
  // console.log(params.career.name);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [formState, setFormState] = useState<FormState>({
    lang: "en",
    category: "",
    name: "",
    overView: "",
    workCulture: "",
  });

  const [otherFormState, setOtherFormState] = useState<Partial<FormState>>({
    lang: "",
    name: "",
    overView: "",
    workCulture: "",
  });

  const { careerFormInputs } = MOCK_DATA;

  const { method: getCategories } = useAxios<ICategory[]>({
    url: "/category/all",
    method: "get",
  });

  const getAllCategories = () =>
    getCategories().then((res) => setCategories(res.data));

  useEffect(() => {
    getAllCategories();
  }, []);

  const [selectedTab, setSelectedTab] = useState(1);
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);

  useEffect(() => {
    setDataTabs(
      dataTabs.filter((item, index) => {
        return index == 0 || index == 1;
      })
    );
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    formState && sessionStorage.setItem("addCareer", JSON.stringify(formState));
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const selectedOption: ICategory = categories.find(
      (option) => option.name === value
    );

    setFormState((prevState) => ({
      ...prevState,
      category: selectedOption.id,
    }));

    // needed?
    formState && sessionStorage.setItem("addCareer", JSON.stringify(formState));
  };

  const handleSubFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setOtherFormState((prevState) => ({
      ...prevState,
      [name]: value,
      lang: selectedTab === 1 ? "ar" : "fr",
    }));

    otherFormState &&
      sessionStorage.setItem(
        "addCareerMultiLang",
        JSON.stringify(otherFormState)
      );
  };

  return (
    <div className={styles.parent}>
      <div
        style={{ flexDirection: "row", display: "flex", marginBottom: "3%" }}
      >
        {MOCK_DATA.languageTabs?.map((item, index) => {
          return (
            <LanguageTabs
              dataTabs={dataTabs}
              item={item}
              tabIndex={index}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setDataTabs={setDataTabs}
              key={index}
            />
          );
        })}
      </div>
      <div className={styles.container}>
        <BreadcrumbChildHeader
          title="Add Career"
          subtitle=" Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."
        />
        <div className={styles.grid}>
          {dataTabs?.map((item, index) => {
            return (
              <div className={index != 0 ? styles.cardInput : null} key={index}>
                <div key={index.toString()} className={styles.col}>
                  <img src={item?.image} alt="uk flag" width={20} />
                  <form className={styles.form}>
                    {index === 0 && (
                      <>
                        <label htmlFor="category" className={styles.label}>
                          Select Category
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "5px",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          id="category"
                          className={styles.selectCategory}
                          onChange={handleOptionChange}
                        >
                          <option value="">Select Category</option>
                          {categories.map((item) => {
                            return (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                    {careerFormInputs.map((formInput) => {
                      return (
                        <div key={formInput.id}>
                          <label className={styles.label}>
                            {formInput.label}
                            {formInput.required && (
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *
                              </span>
                            )}
                          </label>
                          <textarea
                            className={styles.area}
                            style={{
                              resize: "none",
                            }}
                            cols={1}
                            rows={1}
                            placeholder={formInput.placeholder}
                            required
                            name={formInput.name}
                            onChange={
                              index === 0 ? handleChange : handleSubFormChange
                            }
                          />
                        </div>
                      );
                    })}
                  </form>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
