import { IMAGES } from "assets/images";
import { AdminHeader } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";

export const TasksList = () => {
  const [selected, setSelected] = useState(0);

  const handleTaskSelected = (index: number) => {};

  const showAlertModal = () => {};

  const navigation = useNavigate();
  const handleNavigation = () => {
    navigation("dashboard/breadcrumb/AddTasks");
  };
  return (
    <div>
      <AdminHeader />
      <div className={styles.addTaskContainer}>
        <Link to="/dashboard/careers" className={styles.backBtn}>
          <img
            src={IMAGES.ARROW_LEFT}
            alt="arrow-left"
            style={{ textAlign: "center", verticalAlign: "center" }}
            width={20}
          />
          <span>back</span>
        </Link>
        <div className={styles.parent}>
          <div className={styles.container}>
            <div className={styles.tasks}>
              <h3>
                Added Tasks
                <span
                  style={{ fontWeight: "200", fontSize: "16px", color: "gray" }}
                >
                  (50)
                </span>
              </h3>
              <ul className={styles.tasksContainer}>
                {MOCK_DATA.tasksList.map((task, index) => {
                  return (
                    <li
                      key={task.id}
                      className={` ${
                        selected === index ? styles.selected : styles.taskItem
                      }`}
                      onClick={() => {
                        setSelected(index);
                      }}
                    >
                      <span className={styles.label}>{task.label}</span>
                      <img
                        src={IMAGES.DELETE}
                        alt="delete icon"
                        width={25}
                        onClick={() => {}}
                      />
                    </li>
                  );
                })}
                <Link to="" className={styles.addTaskBtn}>
                  Add task
                </Link>
              </ul>
            </div>
            <div className={styles.editTasks}>
              <h3 style={{ color: "blue" }}>Edit task</h3>
              <form>
                <textarea
                  placeholder="Task name"
                  className={styles.customTextArea}
                  style={{ resize: "none" }}
                />
                <textarea
                  placeholder="Add Link"
                  className={styles.customTextArea}
                  style={{ resize: "none" }}
                />
              </form>
            </div>
          </div>
          <div className={styles.btnDiv}>
            <Link to="/dashboard/breadcrumb/AddTasks" className={styles.save}>
              Save
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
