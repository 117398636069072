import { IMAGES } from "assets/images";
import React, { useState } from "react";
import styles from "./style.module.css";

interface ImageInputProps {
  onChange: (file: File) => void;
}

const ImageInput: React.FC<ImageInputProps> = ({ onChange }) => {
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewSrc(URL.createObjectURL(file));
      onChange(file);
    }
  };

  return (
    <div
      className={styles.labelAndImageInputWrapper}
      style={{ display: "flex", marginTop: "16px", flexDirection: "column" }}
    >
      <label htmlFor="imageInput">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            Add Image <small className={styles.optionalText}>(optional)</small>
          </div>
          <div className={styles.selectImg}>
            <small style={selectedFile && { color: "#000", fontSize: "14px" }}>
              {selectedFile ? selectedFile.name : "Select Image"}
            </small>
            <img src={IMAGES.IMPORT} alt="import your image" width={25} />
          </div>
        </div>
      </label>
      <div className={styles.inputAndImage}>
        <input
          type="file"
          accept="image/*"
          id="imageInput"
          name="selectedImg"
          onChange={handleFileInputChange}
          className={styles.imageInput}
          style={{ display: "none" }}
        />
        {previewSrc && (
          <img
            src={previewSrc}
            alt="Selected"
            style={{ maxHeight: "200px", margin: "16px" }}
            width={45}
          />
        )}
      </div>
    </div>
  );
};

export default ImageInput;
