import { IMAGES } from "assets/images";
import {
  AdminHeader,
  ArrowBack,
  BreadcrumbChildHeader,
  CustomFormInput,
  LanguageTabs,
  RequiredSpan,
  Tags,
  Uploading,
} from "components";
import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";
import AddTopic from "pages/addTopic";
import { TopicSelection } from "components/topicSelection";
import { IAddVideo } from "dto/video";
import { useAxios } from "hooks";
import { ITopic } from "models";

export const AddVideo: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [videoName, setVideoName] = useState("");
  const [details, setDetails] = useState("");
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [selectedCareers, setSelectedCareers] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const navigate = useNavigate();
  const [videoFile, setVideoFile] = useState<File>(null);
  const location = useLocation();
  let params = location.state;
  const isEdit = params?.edit;

  // change type to IAddVideo as DTO.

  const [formState, setFormState] = useState<IAddVideo>({
    file: null,
    name: [
      {
        lang: isEdit ? params?.item?.name[0]?.lang : "",
        text: isEdit ? params?.item?.name[0]?.text : "",
      },
    ],
    description: [
      {
        lang: isEdit ? params?.item?.description[0]?.lang : "",
        text: isEdit ? params?.item?.description[0]?.text : "",
      },
    ],
  });

  const [formStateSecond, setFormStateSecond] = useState<IAddVideo>({
    name: [
      {
        lang: isEdit ? params?.item?.name[1]?.lang : "",
        text: isEdit ? params?.item?.name[1]?.text : "",
      },
    ],
    description: [
      {
        lang: isEdit ? params?.item?.description[1]?.lang : "",
        text: isEdit ? params?.item?.description[1]?.text : "",
      },
    ],
  });

  const { loading, method: _add } = useAxios<any>({
    url: `/videos`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    setDataTabs(
      dataTabs.filter((item, index) => {
        return index == 0 || index == 1;
      })
    );
  }, []);

  const handleVideoName = (e: any) => {
    e.preventDefault();
    setVideoName(e.target.value);
    const value = e.target.value;
    if (selectedTab === 1 || selectedTab === 2) {
      setFormStateSecond((prevState: any) => ({
        ...prevState,
        name: [
          {
            lang: "en",
            text: value,
          },
        ],
      }));
    } else {
      setFormState((prevState: any) => ({
        ...prevState,
        name: [
          {
            lang: "en",
            text: value,
          },
        ],
      }));
    }
    console.log(videoName);
  };

  const handleDetailsChange = (e: any) => {
    e.preventDefault();
    setDetails(e.target.value);
    const value = e.target.value;

    if (selectedTab === 1 || selectedTab === 2) {
      setFormStateSecond((prevState: any) => ({
        ...prevState,
        description: [
          {
            lang: "en",
            text: value,
          },
        ],
      }));
    } else {
      setFormState((prevState: any) => ({
        ...prevState,
        description: [
          {
            lang: "en",
            text: value,
          },
        ],
      }));
    }
    console.log(details);
  };

  const handleAddTopic = () => {
    setShowTopicModal(true);
    console.log("modal clicked");
  };

  const handleSelect = (option: ITopic) => {
    console.log("Selected option:", option.name[0].text);
  };

  const handleSubmit = () => {
    console.log("formstate", formState);
    console.log(videoFile);
    let formData = new FormData();
    formData.append("file", videoFile);
    formState.name.forEach((name) => {
      formData.append("name[]", JSON.stringify(name));
    });

    formState.description.forEach((description) => {
      formData.append("description[]", JSON.stringify(description));
    });

    //TODO: add second form state to form data.

    _add({
      data: formData,
    });
  };

  return (
    <>
      <AdminHeader />
      <div className={styles.container}>
        <ArrowBack link="/dashboard/videos" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: ".5rem",
          }}
        >
          {MOCK_DATA.languageTabs?.map((item, index) => {
            return (
              <LanguageTabs
                dataTabs={dataTabs}
                item={item}
                tabIndex={index}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setDataTabs={setDataTabs}
                key={index}
              />
            );
          })}
        </div>
        <div className={styles.addVideoContainer1}>
          <BreadcrumbChildHeader
            title="Add video"
            subtitle=" Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."
          />
          <div className={styles.addVideoContainer}>
            <div className={styles.grid}>
              {dataTabs.map((item, index) => {
                return (
                  <>
                    <form className={styles.form}>
                      {index === 0 ? (
                        <img src={IMAGES.UK_FLAG} width={25} />
                      ) : selectedTab === 1 ? (
                        <img src={IMAGES.EG_FLAG} width={25} />
                      ) : (
                        <img src={IMAGES.FR_FLAG} width={25} />
                      )}
                      <div
                        className={`${index === 0 ? styles.englishForm : ""}`}
                      >
                        <CustomFormInput
                          labelText="Video name"
                          id="videoName"
                          isRequired={true}
                          isBig={false}
                          placeholder="video name"
                          onChange={handleVideoName}
                        />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="detailsArea">
                            Details <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            placeholder="Write Here..."
                            required
                            className={styles.detailsArea}
                            rows={3}
                            id="detailsArea"
                            onChange={handleDetailsChange}
                            style={{ resize: "none" }}
                          />
                        </div>
                        {index === 0 && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label
                                htmlFor="topic"
                                style={{ display: "block", margin: "12px 0" }}
                              >
                                Topic name <RequiredSpan />
                              </label>
                              <TopicSelection onSelect={handleSelect} />
                            </div>
                            {showTopicModal && (
                              <div
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: 9999,
                                  background: "white",
                                  padding: "16px",
                                }}
                              >
                                <AddTopic />
                              </div>
                            )}
                            <Tags
                              addTags={setSelectedCareers}
                              options={[
                                { label: "UI/ UX", value: "ui" },
                                { label: "Development", value: "development" },
                              ]}
                              optional
                              label="Select Career"
                              placeholder="select career"
                            />
                            <Tags
                              addTags={setSelectedMentors}
                              options={[
                                { label: "Amr", value: "amr" },
                                { label: "Rami", value: "rami" },
                              ]}
                              optional
                              label="Select Mentor"
                              placeholder="select mentor"
                            />
                            <Uploading
                              onUpload={setFormState}
                              video={setVideoFile}
                            />
                          </>
                        )}
                      </div>
                    </form>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <NavLink
            to="/dashboard/videos"
            className={styles.btn}
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            Cancel
          </NavLink>
          <button className={styles.btn}>
            {/* TODO: Where to save video drafts? */}
            Save As Draft
          </button>
          <button
            className={styles.btn}
            style={{ backgroundColor: "black", color: "#fff" }}
            onClick={() => {
              handleSubmit();
              navigate("/dashboard/videos");
            }}
            // TODO: onclick to axios post
          >
            Publish
          </button>
        </div>
      </div>
    </>
  );
};
