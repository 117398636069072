import {
  AdminHeader,
  LineLoading,
  VideoApproval,
  VideosDataGrid,
} from "components";
import FilterHeader from "components/filterHeader";
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { deleteVideoApi, getAllPaginatedVideos } from "services/videos";
import { IVideos, IVideo } from "models/videos";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

let page = 1;
let limit = 10;
export const Videos = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IVideo>();
  const [videos, setVideos] = useState<IVideo[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const navigation = useNavigate();

  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  useEffect(() => {
    _getAllVideos(1);
  }, []);

  const _getAllVideos = (page: number) => {
    setLoading(true);
    getAllPaginatedVideos({ page, limit })
      .then((res: any) => {
        setVideos(res.data.docs);
        setPaginationData({
          currentPage: res?.data?.currentPage,
          itemsPerPage: res?.data?.itemsPerPage,
          totalItems: res?.data?.totalItems,
          totalPages: res?.data?.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const _delete = (id: string) => {
    setDeleteLoading(true);
    deleteVideoApi(id)
      .then((_) => {
        toast.success("Topic deleted Successfully");
      })
      .then((_) => {
        setDeleteLoading(false);
        _getAllVideos(page);
      })
      .catch((e) => {
        setDeleteLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <AdminHeader />
      <div className={styles.center}>
        <FilterHeader
          label="Videos"
          isAdd
          onImport={() => {}}
          destination={"/dashboard/addVideo"}
        />
        <VideosDataGrid />
      </div>
      <VideoApproval
        item={selectedItem}
        modalIsOpen={showModal}
        setIsOpen={setShowModal}
      />
    </div>
  );
};

export default Videos;
