import { AdminHeader } from "components";
import FilterHeader from "components/filterHeader";
import NotFound from "components/notFound";
import styles from "./style.module.css";

export const Sessions = () => {
  return (
    <div className={styles.container}>
      <AdminHeader />
      <div className={styles.center}>
        <FilterHeader label="Sessions" onImport={() => {}} />
        <NotFound
          title={"No sessions found yet"}
          description={
            "Lorem ipsum dolor sit amet consectetur. Aliquet risus maecenas."
          }
        />
      </div>
    </div>
  );
};
