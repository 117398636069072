import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import styles from "./style.module.css";
import { IMAGES } from "assets/images";
import Actions from "components/actions";
import { DATAGRIDSTYLE } from "utils";
import { IMentor, IMentorItem } from "models";
import { Typography } from "@mui/material";
import { getAllMentorsApi, getMentorsByIdApi } from "services";
import { toast } from "react-toastify";
import {  MentorInfo } from "components";

const columns: GridColDef[] = [
  {
    field: "mentorName",
    headerName: "Mentor Name",
    flex: 1,
    headerClassName: styles.title,
    renderCell: ({ row: { firstName, lastName, image } }) => {
      return (
        <>
          <img
            src={image ? image : IMAGES.DEFAULT_IMAGE}
            alt="mentor name"
            width={25}
            height={25}
            style={{ borderRadius: "50%", marginRight: "8px" }}
          />
          <span style={{ width: "5px" }} />
          <Typography>
            {firstName} {lastName}
          </Typography>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: styles.title,
    flex: 1,
  },
  {
    field: "subscribtion",
    headerName: "Subscribtion",
    headerClassName: styles.title,
    flex: 1,
    renderCell: ({ row: {} }) => {
      return (
        <>
          <Typography>Example</Typography>
        </>
      );
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    headerClassName: styles.title,
    renderCell: ({ row: {} }) => {
      return (
        <>
          <Typography>Example</Typography>
        </>
      );
    },
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
    flex: 1,
    headerClassName: styles.title,
    disableColumnMenu: true,
    renderCell: () => <Actions />,
  },
];

type GridProps = {
  totalPages?: number;
  currentPage?: number;
  totalItems?: number;
};

export const MentorsDataGrid: React.FC<GridProps> = () => {
  const [mentorIdLoading, setMentorIdLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [data, setData] = useState<IMentorItem>();
  const [mentors, setMentors] = useState<IMentor[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getAllMentors(1);
    console.log(mentors);
  }, []);

  const _getAllMentors = (page: number) => {
    let limit = 100;
    setLoading(true);
    getAllMentorsApi({ page, limit })
      .then((res) => {
        console.log(res);
        setMentors(res.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const _getMentorById = (id: string) => {
    setMentorIdLoading(true);
    getMentorsByIdApi(id)
      .then((res) => {
        setData(res?.data);
        setMentorIdLoading(false);
      })
      .catch((err) => {
        setShowInfo(false);
        setMentorIdLoading(false);
        console.log(Object.values(err?.response?.data?.errors));

        toast.error(`${Object.values(err?.response?.data?.errors)[0]}`);
      });
  };

  const handleRowClick = (params: GridRowParams) => {
    let id = params.row.id;
    console.log("my row clicked", id);
    _getMentorById(id);
  };

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      {!loading && (
        <DataGrid
          sx={DATAGRIDSTYLE}
          rows={mentors}
          columns={columns}
          onRowClick={handleRowClick}
          pageSizeOptions={[10]}
          rowSelection={true}
          disableRowSelectionOnClick
          checkboxSelection={true}
          // paginationMode="server"
          keepNonExistentRowsSelected
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? styles.even
              : styles.odd
          }
        />
      )}

      <MentorInfo
        modalIsOpen={showInfo}
        setIsOpen={setShowInfo}
        item={data}
        loading={mentorIdLoading}
      />
    </div>
  );
};
