import { AccountSettingsSideMenu, AdminHeader } from "components";
import styles from "./style.module.css";
import { Outlet } from "react-router-dom";

export const AccountSettings = () => {
  return (
    <div>
      <AdminHeader />
      <div className={styles.container}>
        <h2 className={styles.title}>Settings</h2>
        <div className={styles.accountSettingsContainer}>
          <AccountSettingsSideMenu />
          <div style={{ width: "100%" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
