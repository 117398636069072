import { IMAGES } from "assets/images";
import { AdminHeader, CareerCard, LineLoading } from "components";
import FilterHeader from "components/filterHeader";
import NotFound from "components/notFound";
import FlatList from "flatlist-react/lib";
import { ICareer } from "models";
import { useEffect, useState } from "react";
import { getCareerApi } from "services/career";
import styles from "./style.module.css";

let page = 1;
let limit = 20;

export const Careers = () => {
  const [careers, setCareers] = useState<ICareer[]>([]);
  const [searchKey, setSearchKey] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  useEffect(() => {
    _getCareers(1);
  }, []);

  const _getCareers = (page: number) => {
    setLoading(true);
    getCareerApi({ page, limit })
      .then((res) => {
        console.log(res);
        setCareers(res.data.data);
        setPaginationData({
          currentPage: res?.data?.currentPage,
          itemsPerPage: res?.data?.itemsPerPage,
          totalItems: res?.data?.totalItems,
          totalPages: res?.data?.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <AdminHeader
        placeholder="Search by name ..."
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
      {loading && <LineLoading />}

      <div className={styles.center}>
        <FilterHeader
          label="Careers"
          onImport={() => {}}
          isAdd
          destination="/dashboard/career"
        />

        {!loading && (
          <>
            <div
              style={{
                overflowY: "auto",
                height: "65vh",
                minHeight: "55vh",
                maxHeight: "65vh",
              }}
            >
              <div className={styles.cards}>
                <FlatList
                  list={
                    searchKey
                      ? careers?.filter((item) => {
                          return item?.name
                            ?.toLowerCase()
                            ?.includes(searchKey.toLowerCase());
                        })
                      : careers
                  }
                  renderItem={(card) => {
                    return (
                      <CareerCard
                        key={card.id}
                        careerTitle={card?.name}
                        createdDate={card?.createdAt}
                        careerDescription={card?.name}
                        photos={card?.image}
                        hasImages={card?.image === "" ? false : true}
                        career={card}
                        searchKey={searchKey}
                        getCareer={() => _getCareers(page)}
                      />
                    );
                  }}
                  renderWhenEmpty={() => (
                    <div style={{ width: "100%" }}>
                      <NotFound title="No careers found" description="" />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className={styles.left}>
              {page != 1 && page == paginationData?.currentPage && (
                <button
                  className={styles.btn}
                  onClick={async () => {
                    page = page - 1;
                    _getCareers(page);
                  }}
                >
                  <img
                    style={{ width: 15, height: 15 }}
                    src={IMAGES.ARROW_LEFT_}
                  />
                </button>
              )}
              <div style={{ marginInline: 5 }}>{page}</div>
              <div>{"of"}</div>
              <div style={{ marginInline: 5 }}>
                {paginationData?.totalPages}
              </div>

              {page < paginationData?.totalPages && (
                <button
                  className={styles.btn}
                  onClick={async () => {
                    page = page + 1;
                    _getCareers(page);
                  }}
                >
                  <img
                    style={{ width: 15, height: 15 }}
                    src={IMAGES.ARROW_RIGHT}
                  />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
