import { IMAGES } from "assets/images";
import styles from "./style.module.css";

interface Props {
  title: string;
  description: string;
}

const NotFound = (props: Props) => {
  const { description, title } = props;
  return (
    <div className={styles.card}>
      <img src={IMAGES.NOT_FOUND} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default NotFound;
