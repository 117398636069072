import { IMAGES } from "assets/images";
import { NotificationsPopup, ProfilePopup } from "components";
import { RootState } from "interface/model";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { MOCK_DATA } from "utils";

interface Props {
  setSearchKey?: any;
  searchKey?: string;
  placeholder?: string;
}

export const AdminHeader: React.FC<Props> = ({
  setSearchKey,
  searchKey,
  placeholder = "Search",
}) => {
  const [profilePopup, setIsOpen] = useState(false);
  const [notificationPopup, setIsNotificationOpen] = useState(false);
  const { user } = useSelector((state: RootState) => state.userState);
  const profileImgRef = useRef(null);
  const profilePopupRef = useRef(null);
  const notificationImgref = useRef(null);
  const notficationPopupRef = useRef(null);
  const { notifications } = MOCK_DATA;
  let notificationsLength = notifications.length;

  window.addEventListener("click", (e: any) => {
    if (
      e.target !== profilePopupRef.current &&
      e.target !== profileImgRef.current
    ) {
      setIsOpen(false);
    }
  });

  window.addEventListener("click", (e: any) => {
    if (
      e.target !== notficationPopupRef.current &&
      e.target !== notificationImgref.current
    ) {
      setIsNotificationOpen(false);
    }
  });

  return (
    <div className={styles.adminHeader}>
      <form className={styles.searchWrapper}>
        <img src={IMAGES.SEARCH} width={15} alt="search icon" />
        <span style={{ width: "8px" }}></span>
        <input
          type="text"
          placeholder={placeholder}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </form>
      <div className={styles.view}>
        <div style={{ position: "relative" }}>
          <img
            src={IMAGES.NOTIFICATION}
            width={25}
            style={{
              display: "inline-block",
              marginRight: "25px",
              cursor: "pointer",
            }}
            alt="notifications"
            ref={notificationImgref}
            onClick={() => setIsNotificationOpen(!notificationPopup)}
          />
          {notificationsLength > 0 && (
            <p className={styles.notificationsLength}>{notificationsLength}</p>
          )}
        </div>
        <img
          src={user?.image ? user?.image : IMAGES.DEFAULT_IMAGE}
          className={styles.img}
          alt="admin profile picture"
          onClick={() => setIsOpen(!profilePopup)}
          ref={profileImgRef}
        />
        {profilePopup && (
          <div ref={profilePopupRef}>
            <ProfilePopup />
          </div>
        )}
        {notificationPopup && (
          <div ref={notficationPopupRef}>
            <NotificationsPopup />
          </div>
        )}
      </div>
    </div>
  );
};
