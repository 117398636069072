import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styles from "./style.module.css";
import { IMAGES } from "assets/images";
import { Typography } from "@mui/material";
import Actions from "components/actions";
import { DATAGRIDSTYLE } from "utils";
import { IUser } from "models";
import { getAllMembersApi } from "services";
import { LineLoading } from "components/lineLoading";
import { useNavigate } from "react-router-dom";

export const UsersDataGrid: React.FC = () => {
  const [rows, setRows] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "User Name",
      flex: 1,
      headerClassName: styles.title,
      renderCell: ({ row: { firstName, lastName, image } }) => {
        return (
          <>
            <img
              src={image ? image : IMAGES.DEFAULT_IMAGE}
              alt="mentor name"
              width={25}
              height={25}
              style={{ borderRadius: "50%", marginRight: "8px" }}
            />
            <span style={{ width: "5px" }} />
            <Typography>
              {firstName} {lastName}
            </Typography>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: styles.title,
      flex: 1,
    },
    {
      field: "subscribtion",
      headerName: "Subscribtion",
      flex: 1,
      headerClassName: styles.title,
      renderCell: ({ row: {} }) => {
        return (
          <>
            <Typography>Example</Typography>
          </>
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      headerClassName: styles.title,
      renderCell: ({ row: {} }) => {
        return (
          <>
            <Typography>Example</Typography>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      flex: 1,
      headerClassName: styles.title,
      disableColumnMenu: true,
      renderCell: ({ row, row: { isActivated } }) => (
        <Actions
          checked={isActivated}
          uploadIcon={true}
          item={row}
          isActiveFlag="user"
          isDelete={false}
          onEdit={() => {
            navigation("/dashboard/editUser", {
              state: {
                item: row,
              },
            });
            console.log("this is my row", row);
          }}
        />
      ),
    },
  ];
  const navigation = useNavigate();

  const _getAllUsers = (page: number) => {
    let limit = 100;
    setLoading(true);
    getAllMembersApi({ page, limit })
      .then((res) => {
        setRows(res.data?.data);
        setTotalPages(res?.data?.totalPages);
        setCurrentPage(res.data.currentPage);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    _getAllUsers(currentPage);
  }, [currentPage]);

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      {loading ? (
        <LineLoading />
      ) : (
        <DataGrid
          sx={DATAGRIDSTYLE}
          rows={rows}
          columns={columns}
          pagination
          autoPageSize
          checkboxSelection={true}
          disableRowSelectionOnClick
          // paginationMode="server"
          // keepNonExistentRowsSelected
        />
      )}
    </div>
  );
};
