import { CircularProgress } from "@mui/material";
import { IMAGES } from "assets/images";
import { AdminHeader, ArrowBack, LanguageTabs } from "components";
import { useAxios } from "hooks";
import { ICategory, ICategoryItem, ITopic, TopicFormState } from "models";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MOCK_DATA } from "utils";
import { topicValidation } from "utils/helperFun";
import styles from "./style.module.css";
import { editTopicApi } from "services";
import { toast } from "react-toastify";

type TopicProps = {
  showModal?: any;
};

const AddTopic: React.FC<TopicProps> = ({ showModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let params = location?.state as { item: ITopic; edit: boolean };
  const isEdit = params?.edit;
  const [selectedTab, setSelectedTab] = useState(1);
  const [editLoading, setEditLoading] = useState(false);
  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [formState, setFormState] = useState<TopicFormState>({
    name: [
      {
        lang: isEdit ? params?.item?.name[0]?.lang : "",
        text: isEdit ? params?.item?.name[0]?.text : "",
      },
    ],
    description: [
      {
        lang: isEdit ? params?.item?.description[0]?.lang : "",
        text: isEdit ? params?.item?.description[0]?.text : "",
      },
    ],
  });
  const [formStateSecond, setFormStateSecond] = useState<TopicFormState>({
    name: [
      {
        lang: isEdit ? params?.item?.name[1]?.lang : "",
        text: isEdit ? params?.item?.name[1]?.text : "",
      },
    ],
    description: [
      {
        lang: isEdit ? params?.item?.description[1]?.lang : "",
        text: isEdit ? params?.item?.description[1]?.text : "",
      },
    ],
  });

  const { loading, method: _add } = useAxios<TopicFormState>({
    url: `/topics`,
    method: "post",
  });

  useEffect(() => {
    console.log({ location, isEdit });
    console.log(params?.item);
  }, []);

  const handleChangeEnglish = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    console.log(name + "/" + value);
    setFormState((prevState) => ({
      ...prevState,
      [name]: [
        {
          lang: "en",
          text: value,
        },
      ],
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tabLang: string
  ) => {
    const { name, value } = event.target;
    console.log("hey" + value);

    setFormStateSecond((prevState: any) => ({
      ...prevState,
      [name]: [
        {
          lang: tabLang,
          text: value,
        },
      ],
    }));
    console.log("2nd", { formStateSecond });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      topicValidation(formState.name[0].text) &&
      topicValidation(formStateSecond.name[0].text)
    ) {
      console.log("Im Here");
      const newFormState = {
        name: [...formState.name, ...formStateSecond.name],
        description: [...formState.description, ...formStateSecond.description],
      };
      console.log("end:", newFormState?.name);
      _add({
        data: {
          name: newFormState.name,
          description: newFormState.description,
        },
        isShowErrorToast: true,
        successMessage: "Topic added successfully",
        Toast: true,
      }).then((_) => {
        navigate("/dashboard/topics");
      });
    }
  };

  const handleEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      topicValidation(formState.name[0].text) &&
      topicValidation(formStateSecond.name[0].text)
    ) {
      const newFormState = {
        name: [...formState.name, ...formStateSecond.name],
        description: [...formState.description, ...formStateSecond.description],
      };
      setEditLoading(true);
      editTopicApi(params?.item?.id, newFormState)
        .then((_) => {
          toast.success("Topic updated Successfully");
          setEditLoading(false);
        })
        .catch((e) => {
          setEditLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      <AdminHeader />

      <div className={styles.card}>
        <ArrowBack link="/dashboard/topics" />

        <div style={{ flexDirection: "row", display: "flex" }}>
          {MOCK_DATA.languageTabs?.map((item, index) => {
            return (
              <LanguageTabs
                dataTabs={dataTabs}
                item={item}
                tabIndex={index}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setDataTabs={setDataTabs}
              />
            );
          })}
        </div>
        <div className={styles.title}>{"Add topic"}</div>
        <div className={styles.subTitle}>
          {"Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."}
        </div>
        <div className={styles.addTopicContainer}>
          <div className={styles.grid}>
            <div>
              <div className={styles.col}>
                <img
                  src={IMAGES.UK_FLAG}
                  alt="uk flag"
                  width={20}
                  style={{ marginBlock: "2px" }}
                />

                <div>
                  {MOCK_DATA.TopicFormInputs.map((formInput, formIndex) => {
                    return (
                      <>
                        <div style={{ height: 12 }} />
                        <label className={styles.label}>
                          {formInput.label}
                          {formInput.required && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>

                        <textarea
                          key={formIndex.toString()}
                          className={styles.area}
                          style={{
                            resize: "none",
                          }}
                          placeholder={formInput.placeholder}
                          required={formInput.required}
                          name={formInput.name}
                          defaultValue={
                            formIndex == 0
                              ? formState?.name[0]?.text
                              : formState?.description[0]?.text
                          }
                          onChange={(e) => {
                            handleChangeEnglish(e);
                          }}
                          cols={1}
                          rows={1}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.cardInput}>
              <div className={styles.col}>
                <img
                  src={selectedTab == 1 ? IMAGES.EG_FLAG : IMAGES.FR_FLAG}
                  alt="uk flag"
                  width={20}
                  style={{ marginBlock: "2px" }}
                />

                {MOCK_DATA.TopicSecondFormInputs.map((formInput, formIndex) => {
                  return (
                    <div key={formIndex?.toString()}>
                      <div style={{ height: 12 }} />
                      <label className={styles.label}>
                        {formInput.label}
                        {formInput.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>

                      <textarea
                        key={formIndex.toString()}
                        className={styles.area}
                        style={{
                          resize: "none",
                        }}
                        placeholder={formInput.placeholder}
                        required={formInput.required}
                        name={formInput.name}
                        defaultValue={
                          formIndex == 0
                            ? formStateSecond?.name[0]?.text
                            : formStateSecond?.description[0]?.text
                        }
                        onChange={(e) => {
                          const tabLang: string =
                            selectedTab === 1 ? "ar" : "fr";
                          handleChange(e, tabLang);
                          console.log({ formIndex });
                        }}
                        cols={1}
                        rows={1}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rowBtn}>
          <button type="submit" className={styles.cancel} disabled={false}>
            <div
              onClick={() => {
                showModal(false);
              }}
              className={styles.cancelTxt}
            >
              {"Cancel"}
            </div>
          </button>
          <button
            type="submit"
            onClick={(e: any) => {
              isEdit ? handleEdit(e) : handleSubmit(e);
            }}
            className={styles.submit}
          >
            {loading || editLoading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <div className={styles.submitTxt}>{"Save"}</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTopic;
