import React, { FormEvent, useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  FormControlLabel,
  Checkbox,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginApi } from "services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../state/slices/user";
import { ROLE, RootState } from "interface/model";
import { useSelector } from "react-redux";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (modalIsOpen: boolean) => void;
}

export const Login: React.FC<Props> = ({ setIsOpen, modalIsOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fcmToken } = useSelector((state: RootState) => state.fcmState);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked);
    console.log(checked);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const onSubmit = (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    loginApi({
      email,
      password,
      firebaseToken: fcmToken,
    })
      .then((res) => {
        console.log({ res });
        console.log(res?.data?.role);
        if (
          res?.data?.role?.type == ROLE.ADMIN ||
          res?.data?.role?.type == ROLE.OWNER
        ) {
          dispatch(setUser(res?.data));

          navigate("/dashboard/overview", { replace: true });
          toast.success("Login successfully..");
        } else {
          toast.error("You can't access this admin panel.");
        }
        setLoading(false);
        closeModal();
        // localStorage.setItem("selectedTab", "0");
      })
      .catch((error) => {
        console.log({ error });
        if (error?.response?.status == 500) {
          toast.error(error?.response?.data?.message ?? "Something error .. ");
        } else {
          let err = Object.values(error.response?.data?.errors)[0] as string;
          toast.error(err ?? "Something error .. ");
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.container}>
        <img
          src={require("assets/images/logo-without-text.png")}
          alt="Brand Logo"
          title="assess"
          className={styles.logo}
        />
        <div className={styles.title}>
          Lorem ipsum dolor sit amet, consectetur.
        </div>

        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.col}>
            <label className={styles.labelTxt}>
              Email <span className={styles.star}>*</span>
            </label>
            <input
              className={styles.input}
              type="email"
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Your email"
              required
            />
          </div>

          <div className={styles.col}>
            <label className={styles.labelTxt}>
              Password <span className={styles.star}>*</span>
            </label>
            <input
              className={styles.input}
              onChange={(e: any) => setPassword(e.target.value)}
              type="Password"
              placeholder="Your password"
              required
            />
          </div>

          <div className={styles.row}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={handleChange}
                  style={{
                    color: checked ? "blue" : "gray",
                  }}
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
              }
              label={<p className={styles.remember}>Remember me</p>}
            /> 
            <div className={styles.forget}>Forgot Password?</div>
            */}
          </div>
          <button type="submit" className={styles.btnCard} disabled={false}>
            {loading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <div className={styles.btnTxt}>Login</div>
            )}
          </button>
        </form>
      </div>
    </Modal>
  );
};
