import { Modal } from "@mui/material";
import React, { useState } from "react";
import styles from "./style.module.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { IMAGES } from "assets/images";

type ModalProps = {
  modalIsOpen: boolean;
  closeModal: (modalIsOpen: boolean) => void;
  title?: string;
  content?: string;
  onChange: any;
  dateRange: any;
  setDateRange: any;
};

export const CalendarModal = ({
  modalIsOpen,
  closeModal,
  title,
  content,
  setDateRange,
  onChange,
  dateRange,
}: ModalProps) => {
  function _closeModal() {
    closeModal(false);
  }

  const handleRangeChange = (ranges: any) => {
    setDateRange([ranges.selection.startDate, ranges.selection.endDate]);
    onChange([ranges.selection.startDate, ranges.selection.endDate]);
  };

  const _submit = () => {
    closeModal(false);
  };

  return (
    <Modal
      open={modalIsOpen}
      disableAutoFocus={true}
      onClose={_closeModal}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.calendar_}>
        <button onClick={_closeModal} className={styles.closeButton}>
          <img src={IMAGES.CLOSEICON} className={styles.close} />
        </button>
        <DateRangePicker
          ranges={[
            {
              startDate: dateRange[0],
              endDate: dateRange[1],
              key: "selection",
            },
          ]}
          onChange={handleRangeChange}
        />

        <button
          type="submit"
          onClick={_submit}
          className={styles.btnCard}
          disabled={false}
        >
          <div className={styles.btnTxt}>{"Submit"}</div>
        </button>
      </div>
    </Modal>
  );
};
