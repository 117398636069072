import { CircularProgress } from "@mui/material";
import { IMAGES } from "assets/images";
import axios from "axios";
import { AdminHeader, UserImage } from "components";
import FilterHeader from "components/filterHeader";
import { IItemPassword, RootState } from "interface/model";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "state/slices/user";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";
import { useAxios } from "hooks";
import { passwordValidation, updateUserValidation } from "utils/helperFun";

export const Settings = () => {
  const { user } = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [selectedIndexMentorType, setSelectedMentorType] = useState(0);
  const [selectedIndexUserType, setSelectedUserType] = useState(0);
  const [selectedIndexVideoType, setSelectedVideoType] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userImage, setUserImage] = useState("");

  const [show, setShow] = useState(false);
  const [selectedIndexPassword, setSelectedIndexPassword] = useState(-1);
  const [oldPassword, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const isDisabledUpdate =
    selectedIndex == 0 &&
    userImage == user?.image &&
    email == user?.email &&
    firstName == user?.firstName &&
    lastName == user?.lastName;

  const { loading, method } = useAxios<any>({
    url: `/users/change-password`,
    method: "patch",
  });

  useEffect(() => {
    setEmail(user?.email);
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setUserImage(user?.image);
  }, []);

  const _submit = () => {
    selectedIndex == 0
      ? !isDisabledUpdate && _updateUser()
      : selectedIndex == 1
      ? _changePassword()
      : _notify();
  };

  const _updateUser = async () => {
    if (updateUserValidation(email, firstName, lastName)) {
      setUpdateLoading(true);
      const data = new FormData();
      userImage != user?.image &&
        data.append(
          "image",
          await fetch(`${userImage}`, {
            headers: {
              "Content-Type": "Access-Control-Allow-Origin",
            },
          })
            .then((e) => {
              console.log({ e });

              return e.blob();
            })
            .catch((err) => {
              console.log({ err });
            })
            .then((blob) => {
              console.log({ blob });

              let b: any = blob;
              b.lastModifiedDate = new Date();
              b.name = "image";
              return b as File;
            })
        );
      data.append("email", email);
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      const config = {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .patch(`${process.env.REACT_APP_BASEURL}/users`, data, config)
        .then((resp) => {
          toast.success("User updated successfully");
          dispatch(setUser(resp.data));
          console.log(resp);
          setUpdateLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
        });
    }
  };

  const _changePassword = () => {
    if (passwordValidation(oldPassword, newPassword, confirmPassword)) {
      method({
        data: {
          newPassword,
          oldPassword,
        },
        Toast: true,
        isShowErrorToast: true,
        successMessage: "Passwords updated successfully",
      });
    }
  };

  const _notify = () => {
    // alert("_notify");
  };

  return (
    <div className={styles.container}>
      <AdminHeader />
      <div className={styles.center}>
        <FilterHeader label="Account Settings" isFilter={false} />
        <div className={styles.card}>
          <UserImage
            setSelectedImage={setUserImage}
            selectedImage={userImage}
          />
          <div className={styles.row}>
            {MOCK_DATA.settings?.map((item, index) => {
              return (
                <div
                  onClick={() => setSelectedIndex(index)}
                  id={item?.id?.toString()}
                >
                  <div
                    className={
                      selectedIndex == index ? styles.name : styles.nameDisabled
                    }
                  >
                    {item?.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.line} />
          {selectedIndex == 0 ? (
            <form>
              <div className={styles.rowInput}>
                <div>
                  <label className={styles.label}>{"First name"}</label>
                  <input
                    className={styles.searchWrapper}
                    type="text"
                    required
                    placeholder="assess"
                    onChange={(e) => {
                      setFirstName(e?.target?.value);
                    }}
                    value={firstName}
                  />
                </div>
                <div>
                  <label className={styles.label}>{"Last name"}</label>
                  <input
                    className={styles.searchWrapper}
                    type="text"
                    required
                    placeholder="user"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e?.target?.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <label className={styles.label}>{"Email"}</label>
                <input
                  required
                  className={styles.emailWrapper}
                  type="email"
                  placeholder="assess@mail.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                  }}
                />
              </div>
            </form>
          ) : selectedIndex == 1 ? (
            MOCK_DATA?.myPasswords?.map((item, index) => {
              return (
                <div key={index?.toString()}>
                  <div key={index?.toString()} className={styles.rowPassword}>
                    <div className={styles.passwordLabel}>{item?.name}</div>
                    <div className={styles.passwordInput}>
                      <input
                        required
                        // onFocus={() => {
                        //   setSelectedIndexPassword(index);
                        // }}
                        className={styles.inputPassword}
                        type={
                          selectedIndexPassword == index && !show
                            ? "password"
                            : "text"
                        }
                        placeholder="•••••••••••••••"
                        onChange={(e) => {
                          index == 0
                            ? setPassword(e.target.value)
                            : index == 1
                            ? setNewPassword(e.target.value)
                            : setConfirmPassword(e.target.value);
                        }}
                      />
                      <span style={{ width: "8px" }}></span>
                      <img
                        onClick={() => {
                          setSelectedIndexPassword(index);
                          setShow(!show);
                        }}
                        src={
                          selectedIndexPassword == index && !show
                            ? IMAGES.EYE
                            : IMAGES.EYE_SLASH
                        }
                        width={15}
                        alt="search icon"
                      />
                    </div>
                  </div>
                  <div className={styles.linePassword} />
                </div>
              );
            })
          ) : (
            <div>
              <div className={styles.rowPassword}>
                <div className={styles.passwordLabel}>{"New user"}</div>
                <div className={styles.notifyView}>
                  {MOCK_DATA.userNotifications?.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUserType(index);
                        }}
                        key={index?.toString()}
                        className={
                          selectedIndexUserType == index
                            ? styles.notifyLabel
                            : styles.notifyLabelDisable
                        }
                      >
                        {item?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.linePassword} />

              <div className={styles.rowPassword}>
                <div className={styles.passwordLabel}>{"New video"}</div>
                <div className={styles.notifyView}>
                  {MOCK_DATA.userNotifications?.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedVideoType(index);
                        }}
                        key={index?.toString()}
                        className={
                          selectedIndexVideoType == index
                            ? styles.notifyLabel
                            : styles.notifyLabelDisable
                        }
                      >
                        {item?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.linePassword} />

              <div className={styles.rowPassword}>
                <div className={styles.passwordLabel}>{"New mentor"}</div>
                <div className={styles.notifyView}>
                  {MOCK_DATA.userNotifications?.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedMentorType(index);
                        }}
                        key={index?.toString()}
                        className={
                          selectedIndexMentorType == index
                            ? styles.notifyLabel
                            : styles.notifyLabelDisable
                        }
                      >
                        {item?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.linePassword} />
            </div>
          )}
        </div>
        <div className={styles.rowBtn}>
          <button type="submit" className={styles.cancel} disabled={false}>
            <div onClick={() => {}} className={styles.cancelTxt}>
              {"Cancel"}
            </div>
          </button>
          <button
            type="submit"
            onClick={_submit}
            className={isDisabledUpdate ? styles.isDisabled : styles.submit}
          >
            {updateLoading || loading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <div
                className={
                  isDisabledUpdate ? styles.isDisabledTxt : styles.submitTxt
                }
              >
                {"Save"}
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
