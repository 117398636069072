import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICareer } from "models";

export interface IInitialState {
  career: ICareer | undefined;
}

const initialState: IInitialState = {
  career: undefined,
};

const careerSlice = createSlice({
  name: "career",
  initialState,
  reducers: {
    setCareer: (state, action: PayloadAction<ICareer | undefined>) => {
      console.log(action.payload);
      state.career = action.payload;
    },
  },
});

export const { setCareer } = careerSlice.actions;
export default careerSlice.reducer;
