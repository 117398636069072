import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAddCareer, ICareerDetails } from "dto/career";

export interface IInitialState {
  addCareer: Partial<IAddCareer> | undefined;
  addDetails: ICareerDetails | undefined;
}

const initialState: IInitialState = {
  addCareer: undefined,
  addDetails: undefined,
};

const addCareerSlice = createSlice({
  name: "addCareer",
  initialState,
  reducers: {
    setAddCareer: (state, action: PayloadAction<IAddCareer | undefined>) => {
      // console.log(action.payload);
      state.addCareer = action.payload;
    },
    setAddCareerWithSubLang: (
      state,
      action: PayloadAction<IAddCareer | undefined>
    ) => {
      state.addCareer = action.payload;
    },
    setCareerDetails: (
      state,
      action: PayloadAction<ICareerDetails | undefined>
    ) => {
      state.addDetails = action.payload;
    },
  },
});

export const { setAddCareer, setAddCareerWithSubLang, setCareerDetails } =
  addCareerSlice.actions;
export default addCareerSlice.reducer;
