import { AdminHeader, CategoriesDataGrid, LineLoading } from "components";
import FilterHeader from "components/filterHeader";
import { useState } from "react";
import styles from "./style.module.css";
import Highlighter from "react-highlight-words";

export const Categories = () => {
  const [searchKey, setSearchKey] = useState<string>("");

  return (
    <div className={styles.container}>
      <AdminHeader
        placeholder="Search by name ..."
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
      <div className={styles.center}>
        <FilterHeader
          label="Categories"
          destination={"/dashboard/addCategory"}
          isAdd
          isFilter={false}
          onImport={() => {}}
        />
      </div>
      <CategoriesDataGrid />
    </div>
  );
};

export default Categories;
