import { useEffect } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type Props = {
  chartData: any;
};

const formatYAxis = (value: string) => `$${value}k`;

export const Chart = ({ chartData }: Props) => {
  useEffect(() => {
    console.log({ chartData });
  }, []);
  return (
    <ResponsiveContainer width="100%" aspect={3.5}>
      <AreaChart
        width={500}
        height={400}
        data={chartData}
        margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="1" horizontal={true} vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={{
            stroke: "transparent",
          }}
          tick={{
            fill: "#ccc",
            fontSize: 14,
          }}
        />
        <YAxis
          tickLine={{
            stroke: "transparent",
          }}
          tick={{
            fill: "#ccc",
            fontSize: 14,
          }}
          tickFormatter={formatYAxis}
        />
        <Tooltip
          cursor={true}
          cursorStyle="pointer"
          contentStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <Area
          type="monotone"
          dataKey="value"
          fillOpacity={0.2}
          fill="#D3D3D3"
          connectNulls={true}
          stroke="blue"
          activeDot={{ stroke: "blue", strokeWidth: 4, r: 5 }}
          dot={false}
          strokeWidth={4}
        />

        {/* <Area
          type="monotone"
          dataKey="value"
          connectNulls={true}
          stroke="blue"
          activeDot={{ stroke: "blue", strokeWidth: 4, r: 5 }}
          dot={false}
          strokeWidth={3}
          area="red"
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};
