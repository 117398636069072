import { CircularProgress, Modal } from "@mui/material";
import React from "react";
import styles from "./style.module.css";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (modalIsOpen: boolean) => void;
  title: string;
  description: string;
  image?: string;
  isTwoBtn?: boolean;
  btnTitle?: string;
  cancelTitle?: string;
  onPress: () => void;
  loading?: boolean;
}

export const Alert: React.FC<Props> = ({
  setIsOpen,
  modalIsOpen,
  image,
  title,
  description,
  isTwoBtn,
  onPress,
  btnTitle,
  cancelTitle,
  loading,
}) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.container}>
        <img src={image} className={styles.image} />
        <div className={styles.title}>{title} </div>
        <div className={styles.description}>{description} </div>

        {isTwoBtn ? (
          <div className={styles.row}>
            <button type="submit" className={styles.cancel} disabled={false}>
              <div onClick={closeModal} className={styles.cancelTxt}>
                {cancelTitle}
              </div>
            </button>
            <button
              type="submit"
              onClick={onPress}
              className={styles.btnCard}
              disabled={false}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                <div className={styles.btnTxt}>{btnTitle}</div>
              )}
            </button>
          </div>
        ) : (
          <button
            type="submit"
            onClick={onPress}
            className={styles.btnCard}
            disabled={false}
          >
            <div className={styles.btnTxt}>{btnTitle}</div>
          </button>
        )}
      </div>
    </Modal>
  );
};
