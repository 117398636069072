import { AxiosResponse } from "axios";
import { PaginationDto } from "dto";
import { IMember } from "models";
import { IVideo } from "models/videos";
import { API } from "utils/interceptor";

export const getAllPaginatedVideos = (
  paginationDto: PaginationDto
): Promise<AxiosResponse<IMember>> => {
  return API.get(
    `/videos?limit=${paginationDto?.limit}&page=${paginationDto?.page}`
  );
};

export const deleteVideoApi = (id: string): Promise<AxiosResponse<IVideo>> => {
  return API.delete(`/videos/${id}`);
};
