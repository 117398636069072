import { IMAGES } from "assets/images";
import {
  AdminHeader,
  CalendarModal,
  Chart,
  DetailCard,
  LineLoading,
  Uploading,
} from "components";
import FilterHeader from "components/filterHeader";
import Loading from "components/loading";
import { useAxios } from "hooks";
import { IItemOverviewCard, IOverview } from "models";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./style.module.css";

export const Overview = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [overviewData, setOverviewData] = useState<IOverview>();
  const [chartData, setChartData] = useState<any>();
  const { loading: isLoading, method: getOverviewData } = useAxios<any>({
    url: "/super-admin/dashboard/overview",
    method: "get",
  });

  const { loading: chartLoading, method: getChartData } = useAxios<any>({
    url: "/super-admin/dashboard/analytics",
    method: "get",
  });

  const _overView = () => {
    getOverviewData().then((res) => {
      setOverviewData(res.data);
    });
  };

  const _chart = () => {
    getChartData({
      isShowErrorToast: true,
    }).then((res) => {
      let arr = [];
      const personArr = Object.entries(res?.data?.data).map(([key, value]) => ({
        key,
        value,
      }));
      const filter = personArr?.map((i) => {
        return i.value;
      });
      arr.push(filter);
      let temp = [
        {
          name: "",
          value: 0,
        },
      ];
      const chartFilterData = arr[0]?.map((i: any) => {
        temp.push({
          name: i?.month,
          value: i?.value,
        });
        console.log(i?.month);
        return temp;
      });
      setChartData(
        chartFilterData[0].filter((item, index) => {
          return index != 0;
        })
      );
    });
  };

  useEffect(() => {
    _overView();
    _chart();
  }, []);

  let overviewDetails = [
    {
      id: 1,
      imgPath: IMAGES.COIN,
      count: overviewData?.totalRevenues,
      percentage: "+10%",
      title: "Total Revenue",
    },
    {
      id: 2,
      imgPath: IMAGES.EYE,
      count: overviewData?.totalViewers,
      percentage: "-10%",
      title: "Total Viewers",
    },
    {
      id: 3,
      imgPath: IMAGES.ARROW2,
      count: overviewData?.totalVisits,
      percentage: "+10%",
      title: "Total Visit",
    },
    {
      id: 4,
      imgPath: IMAGES.IMPORT,
      count: overviewData?.totalDownloads,
      percentage: "+10%",
      title: "Total Download",
    },
  ];

  return (
    <div className={styles.container}>
      <AdminHeader />
      {isLoading && chartLoading && <LineLoading />}
      <div className={styles.center}>
        <FilterHeader onImport={() => {}} label="Overview" />
        {!(isLoading && chartLoading) && (
          <div>
            <div className={styles.cards}>
              {overviewDetails?.map((item: IItemOverviewCard) => {
                return <DetailCard item={item} key={item?.id} />;
              })}
            </div>
            {/* dashboard */}
            <div className={styles.chart}>
              <div className={styles.chartHeader}>
                <h3>Analytics</h3>
                {/* Calendar Div */}
                <div
                  className={styles.calendarContainer}
                  onClick={() => setIsModalOpen(true)}
                >
                  <small>
                    {moment(dateRange[0]).format("ll") +
                      " - " +
                      moment(dateRange[1]).format("ll")}
                  </small>
                  <img src={IMAGES.CALENDAR} alt="calendar" width={25} />
                </div>
              </div>
              <Chart chartData={chartData} />
            </div>
          </div>
        )}
      </div>

      <CalendarModal
        modalIsOpen={isModalOpen}
        closeModal={setIsModalOpen}
        onChange={() => {}}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
    </div>
  );
};
