import React from "react";
import styles from "./style.module.css";
interface Props {
  items: string[];
}

const RowList: React.FC<Props> = ({ items }) => {
  return (
    <div style={{ display: "flex", overflowX: "scroll", marginTop: "8px" }}>
      {items.map((item, index) => (
        <div key={index} style={{ flex: "0 0 auto", marginRight: "10px" }}>
          <div className={styles.itemWrapper}>{item}</div>
        </div>
      ))}
      <style>
        {`
          ::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

export default RowList;
