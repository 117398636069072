import CSS from "csstype";
import { useState } from "react";
import { Contact, Footer, Home, NavBar } from "../components";
import { About } from "../components/about";

export const LandingPageScreen = () => {
  const [isMounted, setIsMounted] = useState(false);
  return (
    <div style={container}>
      <Home setIsMounted={setIsMounted} />
      {true && (
        <>
          <NavBar />
          <About />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
};

const container: CSS.Properties = {
  display: "flex",
  flex: 1,
  // background: "yellow",
  flexDirection: "column",
};
