import { PaginationDto } from "dto";
import { ROLE } from "interface/model";
import { API } from "utils/interceptor";
import { AxiosResponse } from "axios";
import { IMentorItem, IMentorMember } from "models";

export const getAllMentorsApi = (
  paginationDto: PaginationDto
): Promise<AxiosResponse<IMentorMember>> => {
  return API.get(
    `/super-admin/dashboard/mentors?sortType=ASC&role=${ROLE.MEMBER}&limit=${paginationDto?.limit}&page=${paginationDto?.page}`
  );
};

export const getMentorsByIdApi = (
  id: string
): Promise<AxiosResponse<IMentorItem>> => {
  return API.get(`/super-admin/mentors/${id}`);
};
