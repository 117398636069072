import { configureStore } from "@reduxjs/toolkit";
import { reducers } from "./slices";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

// redux-persist config
const persistConfig = {
  key: "root",
  storage,
  transforms: [
    // encrypt and decrypt the data in local storage
    encryptTransform({
      secretKey: "assess@adminPanel.badgewellSecretKey",
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

// create store
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
});

// persist store
const persistor = persistStore(store);

export { store, persistor };
