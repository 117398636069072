import { IMAGES } from "assets/images";
import { BreadcrumbChildHeader, CustomFormInput } from "components";
import { Link } from "react-router-dom";
import styles from "./style.module.css";

export const AddTasks = () => {
  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <BreadcrumbChildHeader
          subtitle="Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."
          title="Add Tasks"
        />
        <div className={styles.forms}>
          <form className={styles.formUk}>
            <img
              src={IMAGES.UK_FLAG}
              alt="unitedKingdom flag"
              width={25}
              style={{ margin: "10px" }}
            />
            <CustomFormInput
              labelText="Task name"
              id="taskName"
              isRequired={false}
              isBig={false}
              placeholder= "Task name"
            />
            <CustomFormInput
              labelText="Link"
              id="link"
              isRequired={false}
              isBig={false}
              placeholder= "Add Link"
            />
            <CustomFormInput
              labelText="Description"
              id="desc"
              isRequired={false}
              isBig={true}
              placeholder= "Write Here..."
            />
            <button className={styles.addTaskBtn}>Add task</button>
            <Link to="/dashboard/tasksList" className={styles.tasksList}>
              <span>Tasks List</span>
              <img src={IMAGES.TASKSQUARE} width={25} />
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};
