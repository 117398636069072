import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { store } from "state";
import { setUser } from "state/slices/user";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  // timeout: 15000,
});

API.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
    };
    const state = store.getState();
    let token = state.userState.user?.accessToken;
    config.headers = {
      ...config.headers,
    };
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      };
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

API.interceptors.response.use(
  function (res) {
    const CancelToken = axios.CancelToken;

    console.log(
      `%c url: ${res.config.url}`,
      "background: #222; color: green",
      res
    );

    return {
      ...res,
      cancelToken: new CancelToken((cancel) =>
        cancel("Cancel repeated request")
      ),
    };
  },
  async function (err: AxiosError) {
    console.log(
      `%c ERROR url: ${err.config?.baseURL}`,
      "background: #222; color: red",
      err.message,
      err.response
    );
    let refresh = false;
    if (err.response) {
      if (err.response.status === 401 && !refresh) {
        refresh = true;
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/users/refresh-token`,
          {
            refreshToken: store.getState().userState.user?.refreshToken,
          }
        );

        if (response.status === 200) {
          store.dispatch(setUser(response?.data));
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response?.data?.accessToken}`;
          return axios(err.config);
        }

        refresh = false;
        return err;
      }
    }
    return Promise.reject(err);

    // throw err as AxiosError;
  }
);

//   .then((res) => {
//     console.log({ res });
//     store.dispatch(setUser(res?.data));
//     axios.defaults.headers.common["Authorization"] =
//       "Bearer " + res?.data?.accessToken;
//   });
// return API.request(originalConfig);
// store.dispatch(setUser(data));
// axios.defaults.headers.common["Authorization"] =
//   "Bearer " + data.accessToken;
// return API.request(originalConfig);
// store.dispatch(setUser(null));
// toast.error("Session Expired,Please Sign in again.", {
//   closeOnClick: false,
//   closeButton: false,
//   pauseOnHover: false,
//   pauseOnFocusLoss: false,
// });

// setTimeout(() => {
//   const win: Window = window;
//   win.location = "/";
// }, 6000);
