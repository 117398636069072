import { combineReducers } from "redux";

import userState from "./user";
import careerState from "./career";
import categoryState from "./category";
import fcmState from "./fcm";
import addCareerState from "./addCareer";

export const reducers = combineReducers({
  userState,
  careerState,
  categoryState,
  fcmState,
  addCareerState,
});
