import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
  fcmToken: string | undefined;
}

const initialState: IInitialState = {
  fcmToken: undefined,
};

const fcmTokenSlice = createSlice({
  name: "fcmToken",
  initialState,
  reducers: {
    setFcm: (state, action: PayloadAction<string | undefined>) => {
      console.log(action.payload);
      state.fcmToken = action.payload;
    },
  },
});

export const { setFcm } = fcmTokenSlice.actions;
export default fcmTokenSlice.reducer;
