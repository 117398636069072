import { Modal } from "@mui/material";
import { IMAGES } from "assets/images";
import Actions from "components/actions";
import Loading from "components/loading";
import RowList from "components/rowList";
import { ICareer, ICareerItem } from "models";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCareerApi } from "services";
import { MOCK_DATA } from "utils";
import styles from "./style.module.css";

type ModalProps = {
  modalIsOpen: boolean;
  closeModal: (modalIsOpen: boolean) => void;
  career: ICareer;
  data: ICareerItem;
  isLoading: boolean;
  getCareer: () => void;
};

const maxPhotos = 4;
const photos = MOCK_DATA.userPhotos;

const visiblePhotos = photos.slice(0, maxPhotos);

export const CareerModal: React.FC<ModalProps> = ({
  closeModal,
  modalIsOpen,
  career,
  data,
  isLoading,
  getCareer,
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>();
  const navigation = useNavigate();

  function _closeModal() {
    closeModal(false);
  }

  const _delete = () => {
    setDeleteLoading(true);
    deleteCareerApi(career?.id)
      .then((_) => {
        toast.success("Career deleted Successfully");
      })
      .then((_) => {
        setDeleteLoading(false);
        _closeModal();
        getCareer();
      })
      .catch((e) => {
        setDeleteLoading(false);
        _closeModal();
      });
  };

  return (
    <Modal
      open={modalIsOpen}
      disableAutoFocus={true}
      onClose={_closeModal}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.modal}>
        {isLoading ? (
          <div className={styles.loading}>
            <Loading simpleLoading={true} />
          </div>
        ) : (
          <>
            <div className={styles.closeDiv}>
              <button className={styles.btnClose} onClick={_closeModal}>
                <img src={IMAGES.CLOSEICON} className={styles.close} />
              </button>
            </div>
            <img
              src={data?.image ? data?.image : IMAGES.NO_IMAGE}
              className={styles.imgWrapper}
              alt="career info"
            />

            <div className={styles.modalHeader}>
              <div className={styles.titleWrapper}>
                <h5 className={styles.title}>{data?.name}</h5>
                <small className={styles.subtitle}>
                  {data?.category?.name}
                </small>
              </div>
              <div>
                {/* row of actions  */}
                <Actions
                  loading={deleteLoading}
                  onDelete={() => {
                    _delete();
                  }}
                  onClose={_closeModal}
                  uploadIcon={false}
                  onEdit={() => {
                    navigation("/dashboard/career", {
                      state: {
                        career,
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className={styles.grayText}>{data?.overView}</div>

            <h5 className={styles.subtitle}>{"Work culture"}</h5>

            <p className={styles.grayText}>{data?.workCulture}</p>
            <h5 className={styles.subtitle}>{"Salary"}</h5>

            {data?.salaries.map((i) => (
              <p className={styles.grayText}>{i}</p>
            ))}

            <h5 className={styles.subtitle}>{"Career areas"}</h5>
            <div className={styles.tags}>
              <RowList items={data?.areas} />
            </div>

            {/* <h5 className={styles.subtitle}>{"Career Mentors"}</h5> */}

            {/* <div className={styles.photos}>
              {visiblePhotos.map((photo) => (
                <div key={photo.src} style={{ marginRight: "-8px" }}>
                  <Photo src={photo.src} alt={photo.alt} />
                </div>
              ))}
              {photos.length > maxPhotos && (
                <div className={styles.numberOfRemainingPhotos}>
                  +{photos.length - maxPhotos}
                </div>
              )}
            </div> */}
          </>
        )}
      </div>
    </Modal>
  );
};
