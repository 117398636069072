import { IMAGES } from "assets/images";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./style.module.css";

interface Props {
  onImport?: () => void;
  onAdd?: () => void;
  label: string;
  isAdd?: boolean;
  isFilter?: boolean;
  destination?: string;
}

const FilterHeader: React.FC<Props> = ({
  label,
  onImport,
  onAdd,
  isFilter = true,
  isAdd = false,
  destination,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.userList}>{label}</div>

      <div style={{ marginLeft: "auto" }}>
        {isFilter && (
          <div className={styles.row}>
            <div className={styles.filterView}>
              <div className={styles.filterName}>{"Filter"}</div>
              <img src={IMAGES.FILTER} className={styles.import} />
            </div>
            <div className={styles.downLoad}>
              <img src={IMAGES.IMPORT} className={styles.import} />
            </div>
            {isAdd && (
              <NavLink to={destination} className={styles.plus}>
                <img src={IMAGES.ADD} className={styles.import} />
              </NavLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
