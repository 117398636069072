import { GridOverlay } from "@mui/x-data-grid";
import { LineLoading } from "components/lineLoading";
import NotFound from "components/notFound";

export function CustomNoRowsOverlay(title: string, description: string) {
  return (
    <GridOverlay>
      <NotFound title={title} description={description} />
    </GridOverlay>
  );
}

export function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <LineLoading />
    </GridOverlay>
  );
}
