import { AdminHeader } from "components";
import FilterHeader from "components/filterHeader";

import styles from "./style.module.css";

import { MentorsDataGrid } from "components";

export const MentorsList: React.FC = () => {


  return (
    <div className={styles.container}>
      <AdminHeader />
      <div className={styles.center}>
        <FilterHeader
          label="Mentors List"
          destination={"/dashboard/addMentor"}
          isAdd
          isFilter= {false}
          onImport={() => {}}
        />
      <MentorsDataGrid />
      </div>

    </div>
  );
};

export default MentorsList;
