import { IMAGES } from "assets/images";
import { BreadcrumbChildHeader, LanguageTabs, Tags } from "components";
import { useEffect, useState } from "react";
import { MOCK_DATA } from "utils";
import styles from "./styles.module.css";
import ImageInput from "components/imageInput";
import { DetailsFormState } from "models";
import { useDispatch } from "react-redux";
import { ITag } from "models/tag";
import { useAxios } from "hooks";

// handle tags input and dispatch the updates
export const AddDetails = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const [dataTabs, setDataTabs] = useState(MOCK_DATA.languageTabs);
  const [tags, setTags] = useState<ITag[]>([]);
  const [mentorsTags, setMentorsTags] = useState([]);

  const [formState, setFormState] = useState<DetailsFormState>({
    careerAreas: null,
    imgPath: null,
    salary: "",
    mentors: null,
  });
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleImageChange = (file: File) => {
    setSelectedImage(file);
    setFormState((prevState) => ({
      ...prevState,
      imgPath: file.name,
    }));
  };

  const { method: getAllTags } = useAxios<ITag>({
    url: "tags/all",
    method: "get",
  });

  const getTags = () => {
    getAllTags().then((res: any) => setTags(res.data));
  };

  useEffect(() => {
    getTags();
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    sessionStorage.setItem("careerDetails", JSON.stringify(formState));
  };

  return (
    <div className={styles.parent}>
      <div
        style={{ flexDirection: "row", display: "flex", marginBottom: "3%" }}
      >
        {MOCK_DATA.languageTabs?.map((item, index) => {
          return (
            <LanguageTabs
              dataTabs={dataTabs}
              item={item}
              tabIndex={index}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setDataTabs={setDataTabs}
              key={item.id}
            />
          );
        })}
      </div>
      <div className={styles.container}>
        <BreadcrumbChildHeader
          subtitle="Lorem ipsum dolor sit amet consectetur. Elementum odio eget arcu."
          title="Add Career"
        />
        <img src={IMAGES.UK_FLAG} width={20} style={{ marginTop: "20px" }} />

        <form className={styles.detailsForm}>
          <Tags
            options={[
              { label: "Figma", value: "figma" },
              { label: "XD", value: "xd" },
              { label: "Photoshop", value: "photoshop" },
              { label: "Illustrator", value: "illustrator" },
            ]}
            name="careerAreas"
            optional
            label="Career Areas"
            placeholder="select career Areas"
            addTags={setTags}
          />
          <ImageInput onChange={handleImageChange} />
          <label style={{ display: "inline-block" }} htmlFor="salary">
            Salary <small style={{ color: "#ccc" }}>(optional)</small>
          </label>
          <input
            type="number"
            style={{ resize: "none" }}
            id="salary"
            placeholder="salary"
            className={styles.salaryArea}
            onChange={handleInputChange}
            name="salary"
          />
          <Tags
            options={[
              { label: "Rami", value: "rami" },
              { label: "Amr Essam", value: "amr" },
            ]}
            optional
            label="Select Mentors"
            placeholder="Select Mentors"
            addTags={setMentorsTags}
          />
        </form>
      </div>
    </div>
  );
};
