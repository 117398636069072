import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import styles from "./style.module.css";
import { Typography } from "@mui/material";
import Actions from "components/actions";
import { DATAGRIDSTYLE } from "utils";
import { deleteCategoryApi } from "services";
import { useNavigate } from "react-router-dom";
import { ICategory } from "models";
import { useAxios } from "hooks";
import { toast } from "react-toastify";
import NotFound from "components/notFound";
import {
  CustomNoRowsOverlay,
  CustomLoadingOverlay,
} from "components/shared/datagridOverlays/customOverlays";

export const CategoriesDataGrid: React.FC = () => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const [categories, setCategories] = useState<ICategory[]>([]);
  // const [selectionModel, setSelectionModel] = React.useState<string[]>([]);

  // const handleSelectionModelChange = (newSelection: string[]) => {
  //   setSelectionModel(newSelection);
  // };

  const { loading, method: getCategories } = useAxios<ICategory[]>({
    url: `/category/all`,
    method: "get",
  });

  const _getAll = () => {
    getCategories().then((res: any) => {
      setCategories(res?.data);
    });
  };
  useEffect(() => {
    _getAll();
  }, []);

  const _delete = (id: string) => {
    setDeleteLoading(true);
    deleteCategoryApi(id)
      .then((_) => {
        toast.success("Category deleted Successfully");
      })
      .then((_) => {
        setDeleteLoading(false);
        _getAll();
      })
      .catch((e) => {
        setDeleteLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Category Name",
      flex: 1,
      headerClassName: styles.title,
    },
    {
      field: "email",
      headerName: "Upload date",
      headerClassName: styles.title,
      flex: 1,
      renderCell: ({ row: uploadDate }) => {
        return (
          <>
            <Typography>22/2/2023</Typography>
          </>
        );
      },
    },
    {
      field: "subscribtion",
      headerName: "Example",
      headerClassName: styles.title,
      flex: 1,
      renderCell: ({ row: uploadDate }) => {
        return (
          <>
            <Typography>Example</Typography>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      flex: 1,
      groupable: false,
      headerClassName: styles.title,
      disableColumnMenu: true,
      renderCell: ({ row, row: { id } }) => (
        <Actions
          loading={deleteLoading}
          onDelete={() => {
            console.log(id);
            _delete(id);
          }}
          onEdit={() => {
            navigation("/dashboard/addCategory", {
              state: {
                row,
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      {!loading && (
        <DataGrid
          sx={DATAGRIDSTYLE}
          rows={categories}
          columns={columns}
          pagination
          autoPageSize
          checkboxSelection={true}
          components={{
            NoRowsOverlay: () =>
              CustomNoRowsOverlay(
                "No Categories found",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries"
              ),
            LoadingOverlay: CustomLoadingOverlay,
          }}
        />
      )}
    </div>
  );
};
