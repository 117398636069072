export const IMAGES = {
  FILTER: require("assets/images/filter.png"),
  DELETE: require("assets/images/delete.png"),
  EDIT: require("assets/images/edit.png"),
  IMPORT: require("assets/images/import.png"),
  UPLOAD: require("assets/images/document-upload.png"),
  ADD: require("assets/images/add.png"),
  PROFILE_HOLDER: require("./profileHolder.png"),
  ANDROID: require("./android.png"),
  ARROW1: require("./arrow.png"),
  ARROW2: require("./arrow-2.png"),
  BATTERYFULL: require("./battery-full.png"),
  BOOK: require("./book.png"),
  BRIEFCASE: require("./briefcase.png"),
  CALL: require("./call.png"),
  COIN: require("./coin.png"),
  DELETE1: require("./delete1.png"),
  DOCUMENT: require("./document.png"),
  EYE: require("./eye.png"),
  USER: require("./user.png"),
  INTERVIEW: require("./interview.png"),
  LANDING: require("./landing.png"),
  LINKED: require("./linked.png"),
  LOCATION: require("./location.png"),
  SEARCH: require("./MagnifyingGlass.png"),
  NOTE: require("./note-2.png"),
  NOTE_ACTIVE: require("./note-2Active.png"),
  NOTIFICATION: require("./notification.png"),
  USER2: require("./profile-2user.png"),
  RECEIPTSEARCH: "./receipt-search.png",
  SMS: require("./sms.png"),
  TASKSQUARE: require("./task-square.png"),
  TEACHER: require("./teacher.png"),
  TWITTER: require("./twitter.png"),
  VIDEO_PLAY: require("./video-play.png"),
  VIDEO: require("./video.png"),
  OVERVIEW_ACTIVE: require("./overviewActive.png"),
  OVERVIEW: require("./overview.png"),
  LOGO: require("./logo.png"),
  USER_ACTIVE: require("./userActive.png"),
  BRIEF_CASE_ACTIVE: require("./briefcaseActive.png"),
  VIDEO_ACTIVE: require("./videoActive.png"),
  TEACHER_ACTIVE: require("./teacherActive.png"),
  VIDEO_PLAY_ACTIVE: require("./video-playActive.png"),
  NOT_FOUND: require("./notFound.gif"),
  LOGOUT: require("./logout.png"),
  PROFILE: require("./profile.png"),
  INFO: require("./info-circle.png"),
  SETTINGS: require("./setting-2.png"),
  SEARCH_INPUT: require("./MagnifyingGlass.png"),
  ALERT: require("./alert.gif"),
  SUCCESS: require("./success.gif"),
  UPLOAD_IMG: require("./uploadImg.png"),
  CALENDAR: require("./calendar.png"),
  THREE_DOTS_VERTICAL: require("./DotsThreeVertical.png"),
  CAREERINFO: require("./careerInfo.png"),
  CLOSEICON: require("./X.png"),
  EYE_SLASH: require("./eye-slash.png"),
  EYE_: require("./eye-.png"),
  ARROW: require("./down.png"),
  UK_FLAG: require("./uk.png"),
  EG_FLAG: require("./egypt.png"),
  FR_FLAG: require("./french.png"),
  ARROW_LEFT: require("./arrow-left.png"),
  CHECK: require("./check.png"),
  PLAY: require("./play.png"),
  RED_CLOSE: require("./redClose.png"),
  LINKED_: require("./linked2.png"),
  EARTH: require("./earth.png"),
  TWITTER_: require("./twitter2.png"),
  DEFAULT_IMAGE: require("./no_image.png"),
  ARROW_RIGHT: require("./arrow-right.png"),
  ARROW_LEFT_: require("./arrow-left-.png"),
  ARROW_DOWN: require("./arrowDown.png"),
  NO_IMAGE: require("./noImage.png"),
  ARROW_UP_Active: require("./arrowUp-active.png"),
  UPLOADING_VIDEO: require("./v.png"),
};
