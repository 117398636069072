import { MOCK_DATA } from "utils";
import styles from "./style.module.css";

export const NotificationsPopup = () => {
  return (
    <div className={styles.popupContainer}>
      <ul
        className={
          MOCK_DATA.notifications.length === 0
            ? styles.noNotifications
            : styles.notificationpopupList
        }
      >
        {MOCK_DATA.notifications.length > 0 ? (
          MOCK_DATA.notifications.map((item) => {
            return (
              <li
                className={styles.notificationpopupItem}
                key={item.id}
                onClick={() => console.log(item.title, "has been clicked")}
              >
                <p className={styles.title}>{item.title}</p>
                <small className={styles.subtitle}>{item.subtitle}</small>
                <div className={styles.line} />
              </li>
            );
          })
        ) : (
          <p>Any Updates will be shown here</p>
        )}
      </ul>
    </div>
  );
};
