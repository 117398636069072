import { IMAGES } from "assets/images";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./style.module.css";

interface Props {
  link: string;
}

export const ArrowBack: React.FC<Props> = ({ link }) => {
  return (
    <NavLink to={link} className={styles.plus}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={IMAGES.ARROW_LEFT} className={styles.left} />
        <div className={styles.back}>{"back"}</div>
      </div>
    </NavLink>
  );
};
