import React, { useEffect, useState } from "react";
import { MOCK_DATA } from "utils";

interface Props {
  item: any;
  tabIndex: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
  setDataTabs: any;
  dataTabs: any;
}

export const LanguageTabs: React.FC<Props> = ({
  item,
  tabIndex,
  selectedTab,
  setSelectedTab,
  setDataTabs,
  dataTabs,
}) => {
  const ChangeTab = () => {
    setSelectedTab(tabIndex);
    if (tabIndex != 0) {
      const firstItem = MOCK_DATA.languageTabs?.filter((item, index) => {
        return index == 0;
      });
      const secondItem = MOCK_DATA.languageTabs?.filter((item, index) => {
        return index == tabIndex;
      });
      let tempData = [firstItem[0], secondItem[0]];
      setDataTabs(tempData);
    }
  };

  return (
    <div
      onClick={() => item?.id !== 1 && ChangeTab()}
      style={{
        padding: 6,
        paddingInline: 16,
        borderRadius: 19,
        marginInline: 8,
        marginTop: "12px",
        marginInlineEnd: 5,
        fontSize: 12,
        border:
          tabIndex == 0
            ? `1px solid #0157FF`
            : selectedTab == tabIndex
            ? `1px solid #0157FF`
            : "",
        cursor: tabIndex != 0 && "pointer",
        backgroundColor:
          tabIndex == 0
            ? "#0157FF"
            : selectedTab == tabIndex
            ? "#EAEFFA"
            : "#EAEFFA",
        color:
          tabIndex == 0
            ? "#ffffff"
            : selectedTab == tabIndex
            ? "#0157FF"
            : "#ffffff",
      }}
    >
      <div key={tabIndex.toString()}>{item?.name}</div>
    </div>
  );
};
