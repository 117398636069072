import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "state/slices/user";
import { MOCK_DATA } from "utils";
import "./profilepopup.css";

export const ProfilePopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _click = (id: number, destination: string) => {
    if (id == 4) {
      dispatch(setUser(undefined));
      navigate("/");
    } else {
      navigate(destination);
    }
  };

  return (
    <div className="popupContainer">
      <ul className="profilepopupList">
        {MOCK_DATA.profile.map((item) => {
          return (
            <li style={{ width: "100%" }} key={item.id}>
              <div
                onClick={() => _click(item?.id, item.destination)}
                className="profilepopupItem"
              >
                <img src={item.icon} alt="holder" width={20} />
                <span>{item.name}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
