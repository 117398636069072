import axios, { AxiosResponse } from "axios";
import { PaginationDto, UserDto } from "dto";
import { ROLE } from "interface/model";
import { IMember, IUser } from "models";
import { API } from "utils/interceptor";

export const loginApi = (dto: UserDto): Promise<AxiosResponse<IUser>> => {
  return axios.post(`${process.env.REACT_APP_BASEURL}/users/login`, dto);
};

export const getAllMembersApi = (
  paginationDto: PaginationDto
): Promise<AxiosResponse<IMember>> => {
  return API.get(
    `/super-admin/dashboard/members?sortType=ASC&role=${ROLE.MEMBER}&limit=${paginationDto?.limit}&page=${paginationDto?.page}`
  );
};
