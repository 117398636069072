import { useEffect, useState } from "react";
import styles from "./style.module.css";
import AddTopic from "pages/addTopic";
import { useAxios } from "hooks";
import { ITopic } from "models";

type Props = {
  onSelect: (option: any) => void;
};

export const TopicSelection = ({ onSelect }: Props) => {
  const [showAddOptionModal, setShowAddOptionModal] = useState(false);
  const [topics, setTopics] = useState<ITopic[]>([]);

  const { method: getTopics } = useAxios<ITopic[]>({
    url: `/topics/all`,
    method: "get",
  });

  useEffect(() => {
    _getAll();
  }, []);

  const _getAll = () => {
    getTopics().then((res) => {
      setTopics(res?.data);
    });
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const selectedOption = topics.find(
      (option) => option.name[0].text === value
    );

    if (selectedOption) {
      onSelect(selectedOption);
    } else {
      setShowAddOptionModal(true);
    }
  };

  return (
    <div>
      <select onChange={handleSelect} className={styles.topicsList}>
        {topics.map((option) => (
          <option key={option.id} value={option.name[0].text}>
            {option.name[0].text}
          </option>
        ))}
        <option value="__add_new__">Add topic</option>
      </select>
      {showAddOptionModal && (
        <div className= {styles.modal}>
          <div className= {styles.modalContent}>
            <AddTopic showModal={setShowAddOptionModal} />
          </div>
        </div>
      )}
    </div>
  );
};
