import { CareerModal } from "components";
import { Photo } from "components/photos";
import { useAxios } from "hooks";
import { ICareer, ICareerItem } from "models";
import moment from "moment";
import { useState } from "react";
import Highlighter from "react-highlight-words";
import styles from "./style.module.css";
import { toast } from "react-toastify";
import { deleteTopicApi } from "services";

type IProps = {
  careerTitle?: string;
  createdDate?: string;
  careerDescription?: string;
  photos?: any;
  hasImages?: boolean;
  mentorsImages?: string[];
  career: ICareer | undefined;
  searchKey?: string;
  getCareer?: () => void;
};

const maxPhotos = 4;

export const CareerCard = ({
  careerDescription, // ask backend
  photos,
  hasImages, // ask backend
  mentorsImages, // ask backend.
  career,
  searchKey,
  getCareer,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const visiblePhotos = photos?.slice(0, maxPhotos);
  const { id, name, createdAt, updatedAt, category, image } = career;
  const formattedTimestamp = moment(createdAt).format("MMMM Do YYYY");
  const [data, setData] = useState<ICareerItem>();

  const { loading: careerByIdLoading, method: careerById } =
    useAxios<ICareerItem>({
      url: `/super-admin/careers/${career?.id}`,
      method: "get",
    });

  const _getCareersById = () => {
    careerById({
      isShowErrorToast: true,
      Toast: true,
    }).then((res) => {
      setData(res?.data);
    });
  };

  return (
    <>
      <div
        className={styles.card}
        onClick={() => {
          _getCareersById();
          setIsModalOpen(true);
        }}
      >
        <div className={styles.container}>
          <div className={styles.upperSide}>
            <div className={styles.info}>
              <Highlighter
                highlightClassName={styles.title}
                searchWords={[searchKey]}
                autoEscape={true}
                textToHighlight={name}
                unhighlightClassName={styles.title}
              />

              <p className={styles.createdDate}>
                created: {formattedTimestamp}
              </p>
            </div>
          </div>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo sunt,
            voluptates porro libero odit accusantium labore quis error iste
            nesciunt corporis, itaque magni? Ut aut recusandae tempora ea
            suscipit repudiandae.
          </p>
          {/* {hasImages && ( */}
          {false && (
            <div className={styles.photos}>
              {visiblePhotos?.map((photo: any) => (
                <div key={photo?.id} style={{ marginRight: "-8px" }}>
                  <Photo src={photo?.src} alt={photo?.alt} />
                </div>
              ))}
              {photos.length > maxPhotos && (
                <div className={styles.numberOfRemainingPhotos}>
                  +{photos?.length - maxPhotos}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CareerModal
        career={career}
        modalIsOpen={isModalOpen}
        closeModal={setIsModalOpen}
        isLoading={careerByIdLoading}
        data={data}
        getCareer={getCareer}
      />
    </>
  );
};
