import { Modal } from "@mui/material";
import { IMAGES } from "assets/images";
import Actions from "components/actions";
import React from "react";
import styles from "./style.module.css";
import { IMentor, IMentorItem } from "models";
import Loading from "components/loading";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (modalIsOpen: boolean) => void;
  item: IMentorItem;
  loading: boolean;
}

export const MentorInfo: React.FC<Props> = ({
  setIsOpen,
  modalIsOpen,
  item,
  loading,
}) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.container}>
        {loading ? (
          <div className={styles.loading}>
            <Loading simpleLoading={true} />
          </div>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.simpleRow}>
                <img src={item?.user?.image} className={styles.userImg} />
                <div>
                  <div className={styles.name}>
                    {item?.user?.firstName + " " + item?.user?.lastName}
                  </div>
                  <div className={styles.career}>{"item?.career"}</div>
                </div>
              </div>
              <div className={styles.actions}>
                <Actions uploadIcon={false} />
              </div>
              <div className={styles.closeDiv}>
                <button className={styles.btnClose} onClick={closeModal}>
                  <img src={IMAGES.CLOSEICON} className={styles.close} />
                </button>
              </div>
            </div>

            <div className={styles.description}>
              {
                "A UX/UI Designer is a professional dedicated to finding an easy, intuitive, and friendly way to solve a problem based on the tastes, needs, and opinions of the users of a product or service. In other words, theyre the person in charge of designing the experience of a client and making sure its optima."
              }
            </div>

            <div className={styles.row}>
              <div>
                <div className={styles.name}>{"Mentor email"}</div>
                <div className={styles.career}>{item?.user?.email}</div>
              </div>

              <div>
                <div className={styles.name}>{"Social links"}</div>
                <div className={styles.simpleRow}>
                  <a
                    className={styles.link}
                    href={"https://www.google.com/"}
                    target="_blank"
                  >
                    <img src={IMAGES.EARTH} className={styles.social} />
                  </a>
                  <a
                    className={styles.link}
                    href={"https://www.google.com/"}
                    target="_blank"
                  >
                    <img src={IMAGES.LINKED_} className={styles.social} />
                  </a>
                  <a
                    className={styles.link}
                    href={"https://www.google.com/"}
                    target="_blank"
                  >
                    <img src={IMAGES.TWITTER_} className={styles.social} />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.name}>{"User type"}</div>
              <div className={styles.career}>{"item?.userPay"}</div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
