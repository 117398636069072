import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "models";

export interface IInitialState {
  user: IUser | undefined;
}

const initialState: IInitialState = {
  user: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | undefined>) => {
      console.log(action.payload);
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
